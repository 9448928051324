<template>

<div class="md-checkbox md-theme-default" :class="{'md-checked': isActive}" @click="set">
    <div class="md-checkbox-container">
        <!-- <div class="md-ripple">
            <input id="md-checkbox-ay5tyweoh" type="checkbox" name="allow_message_notification" true-value="true"> 
            <span class="md-ripple-wave md-centered md-ripple-leave-active md-ripple-leave-to" style="margin-top: -24px; margin-left: -24px; width: 48px; height: 48px;"></span>
        </div> -->
    </div> 
    <label for="md-checkbox-ay5tyweoh" class="md-checkbox-label"><slot /></label>
</div>
</template>

<script>
export default {
     props: ["modelValue"],
    emits: ['update:modelValue'],
    data() {
        return {
            isActive: this.modelValue,
        }
    },
    methods:{  
        set() {
            this.isActive = !this.isActive;
            this.$emit('update:modelValue', this.isActive);
            // this.$emit('change');
            
            
        }
    }
}
</script>

<style lang="scss" scoped>
.md-checkbox.md-theme-default .md-checkbox-container {
    border-color: rgba(0,0,0,.54);
}
.md-checkbox { gap: 10px; margin:0px;
    width: auto;
    display: inline-flex;
    position: relative
}

.md-checkbox:not(.md-disabled),.md-checkbox:not(.md-disabled) .md-checkbox-label {
    cursor: pointer
}

.md-checkbox .md-checkbox-container { position: relative;
    width: 20px;
    min-width: 20px;
    height: 20px;
    position: relative;
    border-radius: 2px;
    border: 2px solid transparent;
    transition: .4s cubic-bezier(.25,.8,.25,1)
}

.md-checkbox .md-checkbox-container:focus {
    outline: none
}

.md-checkbox .md-checkbox-container:after,.md-checkbox .md-checkbox-container:before {
    position: absolute;
    transition: .4s cubic-bezier(.55,0,.55,.2);
    content: " "
}

.md-checkbox .md-checkbox-container:before {
    width: 48px;
    height: 48px;
    top: 50%;
    left: 50%;
    z-index: 6;
    border-radius: 50%;
    transform: translate(-50%,-50%)
}

.md-checkbox .md-checkbox-container:after {
    width: 4px;
    height: 10px;
    top: 0;
    left: 5px;
    z-index: 7;
    border: 2px solid transparent;
    border-top: 0;
    border-left: 0;
    opacity: 0;
    transform: rotate(45deg) scale3D(.15,.15,1)
}

.md-checkbox .md-checkbox-container .md-ripple {
    width: 48px!important;
    height: 48px!important;
    top: 50%!important;
    left: 50%!important;
    transform: translate(-50%,-50%);
    border-radius: 50%
}

.md-checkbox .md-checkbox-container input {
    position: absolute;
    left: -999em
}

.md-checkbox .md-checkbox-label {
    height: auto;
    padding-left: 16px;
    position: relative;
    line-height: 20px;
    
}

.md-checkbox.md-indeterminate .md-checkbox-container:after {
    width: 12px;
    height: 2px;
    top: 50%;
    left: 50%;
    z-index: 4;
    border-style: solid;
    border-width: 0 0 2px;
    opacity: 0;
    transform: translate(-50%,-50%)!important
}

.md-checkbox.md-checked .md-checkbox-container:after { border-color:#fff;
    opacity: 1;
    transform: rotate(45deg) scaleX(1);
    transition: .4s cubic-bezier(.25,.8,.25,1)
}

.md-checkbox.md-disabled.md-checked .md-checkbox-container {
    border-color: transparent!important
}

.md-checkbox.md-required label:after {
    position: absolute;
    top: 2px;
    right: 0;
    transform: translateX(calc(100% + 2px));
    content: "*";
    line-height: 1em;
    vertical-align: top
}

.md-chips.div {
    padding-top: 12px;
    flex-wrap: wrap
}


.md-checkbox.md-theme-default.md-checked .md-checkbox-container { background-color: #dd1a7b; border-color:#dd1a7b; }
.md-radio.md-theme-default.md-checked .md-radio-container { border-color:#dd1a7b; }
.md-radio.md-theme-default.md-checked .md-radio-container:after { background-color: #dd1a7b;}

</style>