import { createRouter, createWebHistory } from "vue-router";

import Home from "../views/home.vue";
import plans from "../views/plans.vue";
import about from "../views/about.vue";
import plan from "../views/plan.vue";
import name from "../views/name.vue";
import partner from "../views/partner.vue";
import subscription from "../views/subscription.vue";
import search from "../views/search.vue";
import category from "../views/category.vue";

const routes = [
	{ path: "/", name: "home", component: Home, props: { bar: "search",header:true }, alias: "/index.html" },
	{ path: "/plans", name: "plans", component: plans, props: { bar: "search" ,header:true}, alias: "/index.html" },
	{ path: "/about", name: "about", component: about, props: { bar: "search" ,header:true}, alias: "/index.html" },
	{ path: "/plan/:id", name: "plan", component: plan},
	{ path: "/name/:id", name: "name", component: name, props: { bar: "search",header:true } },
	{ path: "/partner/:id", name: "partner", component: partner, props: { bar: "search",header:true } },
	{ path: "/subscription/:token", name: "subscription", component: subscription, props: { bar: "search",header:true } },
	{ path: "/search/:q", name: "search", component: search, props: { bar: "search" ,header:true}},
	{ path: "/category/:id", name: "category", component: category, props: { bar: "search" ,header:true} },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	base: process.env.BASE_URL,
	routes,

	scrollBehavior(/*to, from, savedPosition*/) {
		return new Promise((resolve /*, reject*/) => {
			setTimeout(() => {
				resolve({ left: 0, top: 0 });
			}, 200);
		});
	},
});

export default router;
