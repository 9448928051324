<template>

    <div :class="{'md-field': true, 'md-has-value': value != '', focused: focused}">
        <textarea v-if="type=='textarea'" class="md-input" v-model="value" @input="set" @focus="focused=true" @blur="focused=false" :required="must === true" />
        <input v-else :type="type" :min="min" :max="max" class="md-input" v-model="value" @input="set" @focus="focused=true" @blur="focused=false"  :required="must === true">
        <label v-text="label"></label>
    </div>
</template>

<script>
export default {
     props: ["modelValue", "type", "label", "min","max", "must"],
    emits: ['update:modelValue'],
    data() {
        return {
            value: this.modelValue,
            focused:false
        }
    },
    computed: {
     
    },
    methods:{  
        set() {
            this.$emit('update:modelValue', this.value);
        }
    }
}
</script>

<style lang="scss" scoped>
.md-field { /*margin-bottom: 24px;*/ position: relative; margin-top: 11px;}
.md-input { font-size: 22px; color: #0a1130; font-weight: 700; outline: none; width:100%; height: 32px; padding: 0; display: block; flex: 1; border: none; background: none; transition: .4s cubic-bezier(.25,.8,.25,1); transition-property: font-size,padding-top,color; font-family: inherit; line-height: 32px; 
    @media (max-width:600px){ font-size: 16px;  font-weight: normal;}
}

.md-field textarea { height: 80px;}

.md-field label { left: auto; right: 0; color: rgba(0,0,0,.54); pointer-events: auto; position: absolute;
    top: 8px;
    left: 0;
    pointer-events: none;
    transition: .4s cubic-bezier(.25,.8,.25,1);
    transition-duration: .3s;
    font-size: 16px;
    line-height: 20px;}

.md-field input:focus ~ label { top: -11px; opacity: 1; font-size: 12px; }
.md-field textarea:focus ~ label { top: -11px; opacity: 1; font-size: 12px; }
.md-has-value label { top: -11px; opacity: 1; font-size: 12px; }

.md-field:after { height: 1px; background-color: rgba(0,0,0,.42); }

.md-field:before { background-color: #f94480; height: 2px; z-index: 2; opacity: 0; transform: scaleX(.12); }
.md-field:after, .md-field:before { position: absolute; bottom: 0; right: 0; left: 0; z-index: 1; transition: border .3s cubic-bezier(.4,0,.2,1),opacity .3s cubic-bezier(.4,0,.2,1),transform 0s cubic-bezier(.4,0,.2,1) .3s; will-change: border,opacity,transform; content: " "; }

.md-field.focused:before {
    background-color: #f94480;opacity: 1;
    transform: scaleX(1);
    transition: .3s cubic-bezier(.4,0,.2,1);
    transition-property: border,opacity,transform;
}

</style>