<template>
    <div class="banner_wrapper" v-if="bannerItem">
        <div v-html="bannerItem.html" @click.prevent.stop="go"></div>
        
    </div>
</template>

<script>

export default {
    props: ["type"],
    data() {
        return {
            bannerItem: false
        };
    },
    mounted () {
        let data = this.$store.getters.get_banner;

        if (!this.isNull(data[this.type]))
        {
            let items = data[this.type].banners;
            if (items && items.length > 0)
            {
                items = items.filter((row) => {
                    if (this.get_me().is_subscribed)
                    {
                        return (row.is_vip == 1 || row.is_vip == 2);
                    }
                    else
                    {
                        return (row.is_vip == 0);
                    }
                })
            }

            if (items.length == 0)
            {
                this.$emit('close');
                return;
            }

            let itemsNewList = [];

            for (let i in items)
            {
                itemsNewList[i] = { item: items[i], count: this.$store.bannersUsage[items[i].id] };

                if (this.$store.bannersLastBanner[this.type] == items[i].id) itemsNewList[i].count++;
            }

            // Sort by count
            itemsNewList.sort( this.compare );

            // Get the minimal count group
            let minCount = itemsNewList[0].count;
            for (let i in itemsNewList)
            {
                if (itemsNewList[i].count > minCount) itemsNewList.splice(i, 1);
            }

            // console.log(itemsNewList)

            let key = Math.floor(Math.random()*itemsNewList.length);
            let item = itemsNewList[key].item;
            if (!this.isNull(item)) 
            {
                let bannerID = item.id;

                this.$store.bannersUsage[bannerID]++;
                this.$store.bannersLastBanner[this.type] = bannerID;

                this.bannerItem = item;
                return;
            }

        }

        this.$emit('close');
    },
  methods: {
    go(){
        if (this.bannerItem.link_app != '')
        {
            this.$router.push(this.bannerItem.link_app);
        }
        else
        {
            window.open(this.bannerItem.link, '_blank');
        }


        this.$emit('close');
    },
    compare( a, b ) {
        if ( a.count < b.count ){
            return -1;
        }
        if ( a.count > b.count ){
            return 1;
        }
        return 0;
    }
  }

}
</script>