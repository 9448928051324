<template>
	<div class="wrapper">
		<div class="natziv_wrap">
			<div class="natziv" v-for="(name, index) in $store.form.names" :key="index">
				<div class="title">{{ compTitle }}</div>
				<div class="text">{{ compText }}</div>
				<div class="memo_date">{{ (name.day && name.day.length == 1 ? name.day+"'" :  name.day) }} ב{{ name.month }}</div>
				<div class="category">מוקדשות {{ (name.category_text) }} עבור</div>
				<div class="get_name">{{ name.name }}</div>
				<div class="candle" v-if="name.category == 3"></div>
			</div>
		</div>
		<!-- <button @click="send()" class="btn small">מעבר לעמוד התרומה ←</button> -->
	</div>
</template>

<script>
export default {
	props: ["plandata"],
	mounted() {},
	computed: {
		compTitle() {
			if (this.plandata.plan.id == 3) return "שותפי V.I.P";
			if (this.plandata.plan.id == 4) return "שותפים";
			return "נציב יום";
		},
		compText() {
			if (this.plandata.plan.id == 3 || this.plandata.plan.id == 4)
			{
				return "בזכויות התורה והמצוות באתר ובאפליקציית הידברות ביום";
			}
			return "כל זכויות התורה והמצוות באתר ובאפליקציית הידברות ביום";
		},
	},
	methods: {
		send() {
			this.$emit("saveForm");
		},
	},
};
</script>

<style lang="scss" scoped>
.wrapper {
    .sum {
        &{display: flex;justify-content: space-between;align-items: center; gap: 15px;background: white;border-radius: 10px;}
        @media (min-width: 600px) { padding: 30px }
		@media (max-width: 600px) { padding: 20px;flex-direction: column; width: 100%; }
		
		div {
            border: 1px solid rgba(0, 0, 0, 0.25);border-radius: 50px;padding:20px;font-size:25px;
            span {font-weight: bold;}
        }
        .btn {background: #081421;padding:20px;font-size:25px;height: auto;line-height: 20px;}
    }
	.natziv_wrap{
		&{display: flex;margin-top: 0px;}
		@media (min-width: 600px) { gap:30px; }
		@media (max-width: 600px) { flex-direction: column;gap:20px; width: 90%; }
	}
    .natziv {
        &{display: flex;gap:15px;flex-direction: column;align-items: center;text-align: center;background:#4F5861;padding: 30px 60px;border-radius: 10px;}
        *{font-size:20px; color: white}
        .title {font-size: 60px; font-weight: bold;}
        .text {max-width: 170px;}
        .memo_date {font-size:40px}
        .get_name {font-size:40px;line-height: 1;}
    }
    .btn { font-size: 35px;margin: 20px 0;}
}
.small{padding: 20px ;line-height: 1;height: auto;font-size: 25px !important;}
</style>