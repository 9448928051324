<template>
	<div id="app_wrapper" :class="{ rtl: true, menu_open: show_side_menu }">
		<HeaderMbl v-if="loaded && is_mobile()"  @closeMenu="show_side_menu=false" @openSearch="openSearch" />
		<Header v-if="loaded && !is_mobile()" />

		<div id="menu_hamburger" @click="show_side_menu=!show_side_menu">
            <div id="nav-icon3"><span></span><span></span><span></span><span></span></div>
        </div>

		<main id="page" v-if="loaded" :class="{logged: user}">
				<div id="side_menu" v-if="is_mobile()">
					<div class="centered">
						<div id="partners_btn">
						<router-link to="/plans" class="btn" @click="show_side_menu=false"> לשותפות בפרויקט</router-link>
						</div>
						<div id="btn_wrapper">
							<router-link :to="item.link" class="btn" v-for="item in $store.menu" :key="item.id" @click="show_side_menu=false">{{ item.name }}</router-link>
						</div>
						<div class="search">
							<form class="search-box" @submit.prevent="search()">
								<input ref="search_input" type="text" v-model="searchTerm" placeholder="חיפוש">
								<button @click="search()" type="submit"><i class="fa fa-magnifying-glass"></i></button>
							</form>
						</div>
					</div>
				</div>
				<!-- @loaded="is_show_loader = false" @show_loader="is_show_loader = true" -->
				
				<router-view />
		</main>
	</div>
</template>

<script>
import Header from "./components/header.vue";
import HeaderMbl from "./components/header_mbl.vue";
export default {
	name: "app",
	components: { Header, HeaderMbl },
	data() {
		return {
			loaded: false,
			header_class: "",
			searchTerm: '',
			user: false,
			show_side_menu: false,
		};
	},
	mounted() {
		window.appVue = this;
		this.$bus.$on("reload", (loadData, openURL) => {
			this.reload(loadData, openURL);
		});
		this.$store.plans = [];
		this.$store.menu = [];
		this.$store.menu = [];
		this.$store.categories = [];

		

		this.api({ action: "memorial_categories" }, (data) => {
			this.$store.categories = data.data.categories;
			
			
		});
		this.api({ action: "home_new" }, (data) => {
			this.$store.today_names = data.data.today_names;
			this.$store.heb_day = data.data.heb_day;
			this.$store.heb_month = data.data.heb_month;
			this.$store.plans = data.data.plans;
			this.$store.state.plans = data.data.plans;
			this.$store.menu = data.data.menu;
			this.$store.words = data.data.words;
			this.loaded = true;
		});
		
			this.$nextTick(()=>{});
		// this.reloadPage();
	},
	methods: {
		search(){
			this.$router.push({ path: "/search/"+this.searchTerm});
			this.show_side_menu = false;
			this.$refs.search_input.blur();
		},
		openSearch(){
			console.log('sas');
			this.show_side_menu = true;
			this.$refs.search_input.focus();
		},
		reload(loadData, openURL) {
			this.loaded = false;

			if (this.isNull(openURL)) openURL = "/";

			if (this.$route.path !== "/") this.$router.push(openURL);
			//this.refreshKey++;
			if (loadData !== false) this.reloadPage();
			else this.loaded = true;
		},

		reloadPage(cb = null) {
			let getVars = {};
			let uri = window.location.href.split("?");
			if (uri.length == 2) {
				let vars = uri[1].split("&");

				let tmp = "";
				vars.forEach(function (v) {
					tmp = v.split("=");
					if (tmp.length == 2) getVars[tmp[0]] = tmp[1];
				});
				// do
			}

			if (!this.isNull(getVars.token)) {
				this.set_user_token(getVars.token);
				document.location = "/";
			}

			// categories
			this.api({ action: "general", method: "get" }, (data) => {
				// this.menu = data.data.menu;
				this.$store.words = data.data.words;

				this.$store.user = data.data.user;
				this.user = data.data.user;

				this.show_login = !this.user;

				
				// self.show_splash_screen = false;
				if (cb && typeof cb === "function") cb();
			});
		},
	},
};
</script>
<style scoped lang="scss">
</style>