<template>
	<div id="plan_wrapper" v-if="loaded" class="wrapper" :class="{order_ended: step === 5}">
		<div v-if="!is_mobile() || step === 1" class="top">
			<div class="details">
				<div class="chosen">
					המסלול הנבחר:
					<span :style="'background: ' +plan.color +';border-radius: 6px; color:black;padding: 0 5px;text-align: center;'">{{ plan.name }}</span>
				</div>
				<div class="num" v-if="plan.id != 4">מספר שמות להנצחה: {{ plan.id == 2 ? 3 : 1 }}</div>
			</div>
		</div>
		<OrderHeader v-if="step > 1" :step="step" :key="planKey" :plandata="{ plan }" @go_back="goBack" @plan_changed="plan_changed" @saveForm="saveForm" />
		<PersonalDetails v-if="step == 1" @nextStep="stepUp" :plan_id="+plan_id"   />
		<MemorialNames v-if="step == 2" :plan_id="plan_id" :planData="plan" @next_step="stepUp" @save_memorial_name="save_memorial_name" :key="planKey" />
		<OrderSummary v-if="step == 3 && +plan.id !== 4" :plandata="{ plan }" @saveForm="saveForm()" :key="planKey"/>
		<div class="payment_wrapper" v-if="step == 4 && paymentId > 0">
			<div v-if="!showPayment" class="lds-ring"><div></div><div></div><div></div><div></div></div>
			<!-- <iframe width="100%" height="500" :class="{active : showPayment}" :src="'https://hdbmemorial.newdev.wizzo.co.il/payments/do_redirect/' +paymentId"></iframe> -->
			<payment v-else :paymentData="paymentData" @payment_success="handlePaymentSuccess" @success_update="thanks_update" @fail_msg="failMsg" @fail="fail()" @cancel="payment_url=false" />
		</div>
	</div>
	<EndOrder :order_id="order_id" :plandata="{ plan }" v-if="step == 5" />
</template>
<script>
import MemorialNames from "@/components/MemorialNames.vue";
import PersonalDetails from "@/components/PersonalDetails.vue";
import OrderSummary from "@/components/OrderSummary.vue";
import OrderHeader from "@/components/OrderHeader.vue";
import EndOrder from "@/components/EndOrder.vue";
import Payment from '@/components/payment.vue';
export default {
	components: { PersonalDetails, MemorialNames, OrderSummary, EndOrder , OrderHeader,Payment},
	data(){
		return {
			plan_id: this.$route.params.id,
			name: "",
			id_card: "",
			get_name: "",
			phone: "",
			address: "",
			email: "",
			planKey: 0,
			paymentId: 0,
			order_id: 0,
			category: "",
			memorial_date: "",
			showPayment: false,
			shareMail: false,
			shareWhatsapp: false,
			step: 1,
			loaded: false,
			plan: {},
			paymentData: {},
		};
	},
	computed: {
		compStepName() {
			if (this.step == 1) return "הזנת פרטים אישיים";
			else if (this.step == 2) return "הזנת פרטי ההנצחה";
			else if (this.step == 3) return "סיכום הזמנה";
			else if (this.step == 4) return "הזנת פרטי אשראי";
			else if (this.step == 5) return "סיכום הזמנה";

			return "";
		},
		planComp(){
			let selectedPlan = this.getPlanJSON();
			return this.$store.plans.find(plan=>+plan.id === +selectedPlan.id);
		}
	},
	methods: {
		goBack(){
			if(this.plan_id && this.step === 3) return this.step = 1;
			this.step--;
		},
		failMsg(){
			this.alert(this.get_word('payment_creation_failed'), 'error');
		},
		handlePaymentSuccess(orderId){
			this.order_id = orderId;
			this.step = 5;
			console.log('payment success from plan');
		},
		plan_changed(data, changeStep = true){
			let plan = this.plan;
			if(changeStep){
				if(this.plan.id == 4 && this.step <= 4) 
				{
					this.step = 2;
				}
				if(data == 4 && this.step <= 2) 
				{
					this.step = 3;
				}
				
				

			}

			for(let i in this.$store.plans)
			{
				if(this.$store.plans[i].id == data)
				{
					const selectedPlan = this.$store.plans[i];
					if(plan.num_names != selectedPlan.num_names) {
						this.step = 2;
					}
					this.plan = selectedPlan;
					this.saveNamesJson([]);
				}
			}
			this.planKey++;
		},
		save_memorial_name(names) {
			this.$store.form.names = names;
			this.saveNamesJson(names);
			this.saveForm(names);
		},
		saveForm(names = []) {
			this.showPayment = false;
			
			let plan = this.getPlanJSON();
			let form = this.getDataJSON();
			this.api({ action: "save_form", data: {form,plan, names, is_test: true} }, (data) => {
				if (+data.data.id > 1) {
					this.paymentId = +data.data.id;
					this.paymentData = {
						name: data.data.name,
						price: +data.data.amount + '₪',
						id:data.data.id,
						// charge:true
					};
					
					setTimeout(()=>{
						this.showPayment = true;
					},1000);

					window.scrollTo({ top: 0, behavior: "smooth" });
					if(names.length < 1) this.step = 4;
					else this.step = 3;
					
				}
			});
			window.scrollTo({ top: 0, behavior: "smooth" });
			this.step = 5;
		},
		stepUp() {

			if(this.plan_id == 4)
			{
				window.scrollTo({ top: 0, behavior: "smooth" });
				this.step = 3;
			}
			else{
				window.scrollTo({ top: 0, behavior: "smooth" });
				this.step++;
			}

		},
	},
	mounted() {
		this.$store.form = {
			name: "",
			phone: "",
			id_card: "",
			email: "",
			address: "",
			plan_id: this.plan_id,
			names: [],
		};

		this.api({ action: "plans/get", data: { id: this.plan_id } }, (data) => {
			this.plan = data.data;
			const plan = this.getPlanJSON();
			plan.id = this.plan_id;
			plan.num_payments = +data.data.months || 1;
			this.savePlanJson(plan);

			this.loaded = true;
		});
		this.api({ action: "memorial_categories" }, (data) => {
			this.$store.categories = data.data.categories;
		});

		window.addEventListener("message", (event) => {
			if (event.data && event.data.length > 0){
				if( event.data.startsWith("payment_completed") )
				{
					setTimeout(() => {
						let data = event.data.split("+");
						if(+data[1] && +data[1] > 0){
							this.order_id = +data[1];
							this.api({ action: "send_names", data: { order_id: this.order_id } });
						}
						this.alert("תשלום בוצע בהצלחה!");
						this.step++;
						// this.$bus.$emit("reload");
					}, 1500);
				}
			}
		});
	},

};
</script>

<style lang="scss" scoped>
#plan_wrapper{
	@media (max-width: 600px) {padding-bottom: 80px;}
	@media (min-width: 600px) {padding-bottom: 30px;}

	&.order_ended{
		&>.wrapper, &>.top{display: none;}
	}
}
		.input_wrapper {position: relative;
			&:focus-within label,
			input:not(:placeholder-shown) + label{transform: translateY(-50%);top: 0;background-color: #fff;}
			label {position: absolute;transition: all .25s;right: 20px;
				@media (max-width: 600px) {flex: 0.5;}
				@media (min-width: 600px) {}
			}


		}

.payment_wrapper{
	@media (max-width: 600px) {width: 95%;}
	@media (min-width: 600px) {}
}
	iframe{height: 500px; width: 550px; border-radius: 30px; border: none; outline: none;max-height: 0px;overflow: hidden;transition: 0.4s max-height ease-in-out;
		@media (max-width: 600px) { width: 100%;margin:0 auto; border-radius: 0px;height: 700px;  }
	}
	iframe.active{max-height: 500px;
			@media (max-width: 600px) { max-height: 700px; }
	}
    .wrapper{
        &{position: relative;display: flex;flex-direction: column;justify-content: center;align-items: center;width: 1300px;margin: 0 auto;max-width: 100%;}
        @media (min-width: 600px) {  }
		@media (max-width: 600px) { width: 95%;padding: 10px 0; }
		.top {
            &{font-size: 25px;color:white;margin-bottom: 30px;display: flex;flex-direction: column;justify-content: center;
			align-items: center;gap:10px;width: 100%;}

			@media (max-width: 600px) {padding-top: 34px;}
			@media (min-width: 600px) {}
            .title {
				@media (min-width: 600px) {font-size:60px}
				@media (max-width: 600px) {font-size:40px;}
			}
            .details {
				&{display: flex; align-items:  center; }
				@media (min-width: 600px) {font-size: 35px; gap:20px;justify-content: center;}
				@media (max-width: 600px) { justify-content: space-around;width: 100%;}
				.chosen {
					@media (min-width: 600px) {}
					@media (max-width: 600px) {display: flex;flex-direction: column;gap:5px }
				}
			}
        }
        .btn {
			&{border: 1px solid #dd1a7b;font-size: 35px;background: none;}
			@media (min-width: 600px) {position: absolute;top:20px;left:10%;font-size:22px}
			@media (max-width: 600px) {}
		}
		form{
			&{display: flex;flex-direction: column;justify-content: space-between;gap:15px;align-items: center;background: white;
			width: fit-content;border-radius: 30px;}
			@media (min-width: 600px) { padding: 50px; }
			@media (max-width: 600px) { padding: 20px;width: 100%; }
		}
    }
</style>