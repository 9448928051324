const globalMixin = {
	data() {
		return {
			// site_url: 'https://www.7brachot.co.il/'
		};
	},
	computed: {
		
	},

	methods: {
		getDataJSON(){
			let data = sessionStorage.getItem('formData');
			if (!this.isNull(data)) return JSON.parse(data);
			else
			{
				data = {
					name: '',
					email: '',
					idCard: '',
					phone: '',
					city: '',
					day: '',
					month: ''
				};

				sessionStorage.setItem('formData', JSON.stringify(data));

				return data;
			}
		},
		saveNamesJson(form) {
			// Assuming form is an array of names
			sessionStorage.setItem('namesData', JSON.stringify(form));
		},
		getNamesJson() {
			let data = sessionStorage.getItem('namesData');
		
			if (!this.isNull(data)) {
				return JSON.parse(data);
			} else {
				data = []; // Initialize as an empty array if there is no existing data
				sessionStorage.setItem('namesData', JSON.stringify(data));
				return data;
			}
		},

		saveDataJson(form){
			let data = this.getDataJSON();
			for (let i in form)
			{
				data[i] = form[i];
			}

			sessionStorage.setItem('formData', JSON.stringify(data));
		},
		getPlanJSON(){
			let data = sessionStorage.getItem('planData');
			if (!this.isNull(data)) return JSON.parse(data);
			else
			{
				data = {
					id: 1,
					num_payments: 1
				};
				sessionStorage.setItem('planData', JSON.stringify(data));
				return data;
			}
		},
		savePlanJson(form){
			let data = this.getPlanJSON();
			for (let i in form)
			{
				data[i] = form[i];
			}

			sessionStorage.setItem('planData', JSON.stringify(data));
		},
		userToken() {
			if (!this.isNull(localStorage.getItem("user_token"))) {
				return true;
			}

			return false;
		},

		bg(pic) {
			return "background-image:url(" + pic + ")";
		},
		get_cat(id) {
			for (let i in this.$store.categories) {
				if (id == this.$store.categories[i].id) {
					return this.$store.categories[i].name;
				}
			}
			return "";
		},

		get_me() {
			return this.$store.user;
		},
		get_word(key) {
			// if (this.isNull(gender)) {
			// 	let me = this.get_me();
			// 	if (me) gender = me.gender;
			// 	else gender = "male";
			// }
			return this.$store.words[key];
			// check subs
			// if (this.isNull(this.$store.words[key])) {
			// 	for (let i in this.$store.words) {
			// 		if (!this.isNull(this.$store.words[i].sub) && !this.isNull(this.$store.words[i].sub[key])) {
			// 			if (gender == "female") return this.$store.words[i].sub[key].value_female;
			// 			else return this.$store.words[i].sub[key].value_male;
			// 		}
			// 	}
			// 	return "";
			// }
			// if (gender == "female") return this.$store.words[key].value_female;
			// else return this.$store.words[key].value_male;
		},

		format_number: function (x) {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		isNull: function (str) {
			return str == "" || str == null || str == undefined;
		},
		alert(msg, icon = '') {
			//this.$alert(msg);
			const swalSettings = { html: msg, confirmButtonText: "אוקי", showCloseButton: true };
			if(icon) swalSettings.icon = icon;
			this.$swal.fire(swalSettings);

			//this.$toasted.show(msg, { type: 'success', theme: "toasted-primary", position: "top-left", duration : 3000 } )
		},
		pushIfNotExist(element, comp) {
			if (element.indexOf(comp) == -1) {
				element.push(comp);
			}
		},
		getCardTitle(id){
			switch (id) {
				case '1': return "נציב יומי";
				case '2': return "מייסדים";
				case '3': return "שותפי V.I.P";
				case '4': return "שותפים";
				default: return "נציב יום" ;
			}
		},
		getCardTitleEnd(id){
			switch (id) {
				case '1': return "מנציב יומי";
				case '2': return "מהמייסדים";
				case '3': return "משותפי הV.I.P";
				case '4': return "מהשותפים";
				default: return "נציב יום" ;
			}
		},
		getCardText(id) {
            if (id == 3 || id == 4) return "בזכויות התורה והמצוות באתר ובאפליקציית הידברות ביום";
            return "כל זכויות התורה והמצוות באתר ובאפליקציית הידברות ביום";
        },
		pushOrPull(element, comp) {
			var index = element.indexOf(comp);
			if (index == -1) {
				element.push(comp);
			} else {
				element.splice(index, 1);
			}
		},
		inArray(element, comp) {
			return element.indexOf(comp) != -1;
		},

		set_user_token(str) {
			localStorage.setItem("user_token", str);

			var myDate = new Date();
			myDate.setMonth(myDate.getMonth() + 12);
			let cookieName = "app_user";
			let cookieValue = str == "" ? "0" : "1";
			document.cookie = cookieName + "=" + cookieValue + ";expires=" + myDate + ";domain=.7brachot.co.il;path=/";
		},

		api(settings, func, failFunc) {
			if (this.isNull(func)) func = function () {};

			var headers = {
				"Content-Type": "application/json; charset=utf-8",
			};

			if (!this.isNull(localStorage.getItem("user_token"))) {
				headers.Authorization = localStorage.getItem("user_token");
			}

			//         var requestOBJ;
			const cancelTokenSource = this.axios.CancelToken.source();

			if (settings.method == "get") {
				let actionGet = settings.action;
				actionGet = actionGet + (actionGet.indexOf("?") === -1 ? "?" : "&");
				actionGet =
					actionGet + "ts=" + new Date().getHours() + "_" + (new Date().getMinutes() < 30 ? "0" : "30");

				if (!this.isNull(settings.data)) {
					for (let i in settings.data) actionGet += "&" + i + "=" + settings.data[i];
				}

				this.axios
					.get(window.api_url + actionGet, { headers: headers, cancelToken: cancelTokenSource.token })
					.then(
						(response) => {
							if (response.data == "error") {
								this.set_user_token("");
								document.location.reload();
							}

							func(response.data);
						},
						(response) => {
							if (!this.isNull(failFunc)) failFunc(response);
							//else func(false);
						}
					);

				// Cancel request
				//cancelTokenSource.cancel();
			} else {
				this.axios
					.post(window.api_url + settings.action, settings.data, {
						headers: headers,
						cancelToken: cancelTokenSource.token,
					})
					.then(
						(response) => {
							if (response.data == "error") {
								this.set_user_token("");
								this.$bus.$emit("reload");
							}
							func(response.data);
							//console.log(response.body);
						},
						(response) => {
							if (!this.isNull(failFunc)) failFunc(response);
							//else func(false);
							//console.error(response.body);
						}
					);
			}

			return cancelTokenSource;
		},

		is_mobile() {
			const userAgent = navigator.userAgent.toLowerCase();
			const isTablet =
				/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
					userAgent
				);
			if (isTablet) return false;

			const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

			return toMatch.some((toMatchItem) => {
				return navigator.userAgent.match(toMatchItem);
			});
		},

		getMobileOperatingSystem() {
			var userAgent = navigator.userAgent || navigator.vendor || window.opera;

			// Windows Phone must come first because its UA also contains "Android"
			if (/windows phone/i.test(userAgent)) {
				return "Windows Phone";
			}

			if (/android/i.test(userAgent)) {
				return "Android";
			}

			// iOS detection from: http://stackoverflow.com/a/9039885/177710
			if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
				return "iOS";
			}

			return "unknown";
		},
	},
};

export default globalMixin;
