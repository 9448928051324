<template>
    <div class="home">
        <div id="hero_home" class="centered">
            <h1 v-text="'תוצאות ל - ' + q"></h1>
            <div class="centered">
                <div class="items_wrapper">
                    <template v-if="names && names.length > 0">
                        <router-link class="item" v-for="(name,i) in names" :key="i" :to="name.link">
                            <div class="title">{{ getCardTitle(name.plan_id) }}</div>
                            <div class="text">{{ getCardText(name.plan_id) }}</div>
                            <div class="donor_name">נתרם ע"י {{ name.donor_name }}</div>
                            <div class="memo_date">{{ name.memorial_date }}</div>
                            <div class="category">מוקדשות {{ (name.category_text) }} עבור</div>
                            <div class="get_name">{{ name.name }}</div>
                            <div class="candle" v-if="name.category == 3"></div>
                        </router-link>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components:{  },
    
    data() {
        return {
            q: (this.isNull(this.$route.params.q) ? '' : this.$route.params.q),
            names: [],
        };
    },
    computed: {},
    mounted() {
        this.doSearch();
    },
    methods: {
        doSearch(){
            this.api({ action: 'names/results', data: {q: this.q}}, (data)=>{
                this.names = data.data.items;
                console.log(this.names);
            });
        }
    },
    watch:{
        '$route.params': function(){
            this.q = this.$route.params.q;
            this.doSearch();
        }
    }
};
</script>

<style lang="scss" scoped>
h1{color: #fff;padding: 50px 0 0;
    @media (max-width: 600px) {text-align: center;}
    @media (min-width: 600px) {}
}
.items_wrapper{
		@media (min-width: 600px) { gap:30px; display: grid; grid-template-columns: repeat(5, 1fr);grid-auto-rows: 1fr;padding: 50px 0;}
		@media (max-width: 600px) { display: flex;flex-direction: column; gap:20px;padding: 20px;}
		.item {
			&{display: flex;gap:15px;flex-direction: column;align-items: center;text-align: center;background:#4F5861;border-radius: 10px;position: relative;}

            @media (max-width: 600px) {padding: 20px;}
            @media (min-width: 600px) {padding: 40px 60px 30px;}

			*{font-size:20px; color: white}
			.title {font-size: 60px; font-weight: bold;}
			.text {max-width: 170px;}
			.donor_name {font-size: 25px;position: absolute;top: 10px;left: 10px;}
			.memo_date {font-size:40px}
			.get_name {font-size:40px;line-height: 1;}
			.candle {background: url("../assets/candle.png");width: 30px; height: 30px;background-size: cover;}
		}
	}
</style>