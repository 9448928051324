<template>
  <header id="header_mbl">
    <div id="header_inner">
        <router-link to='/' id="logo" class="title_cls logo" @click="$emit('closeMenu')"></router-link>
        <div class="header_left_wrapper">
          <router-link to='/plans' id="btn_go_to_plans" class="btn" @click="$emit('closeMenu')">{{get_word('to_join_project_mbl')}}</router-link>
          <button class="clean_btn btn_open_search" @click="$emit('openSearch')">
            <i class="fa fa-magnifying-glass"></i>
          </button>
        </div>
    </div>
    </header>
</template>

<script>
export default {
	props: ["user"],
	emits: ["closeMenu", "openSearch"],
	components: {},
};
</script>

<style lang="scss" scoped>
#header_mbl{
  &{background-color: #081421;position: sticky;top: 0;height: var(--header_mbl_height);z-index: 99999;border-bottom: 1px solid #ccc;}
  #header_inner{
    &{position: relative;height: 100%;width: 100%;display: flex;align-items: center;justify-content: space-between;padding-right: 45px;}
    .logo{/*position: absolute;top: 50%;transform: translateY(-50%);left: 0;right: 0;margin: 0 auto;*/ width: 125px;height: 80%;background-image: url(@/assets/logo.svg);background-size: contain;background-position:center;background-repeat: no-repeat;

    }
    .btn_open_search{color: #fff;font-size: inherit;line-height: 1;}
    .icons_wrapper{position: absolute;left: 50px;top: 50%;transform: translateY(-50%);}
    .header_left_wrapper{display: flex;align-items: center;height: 100%;justify-content: flex-end;padding-left: 10px;gap: 15px;}
  }
  #user_side { display: flex; gap:15px; align-items: center;}
 

}

</style>