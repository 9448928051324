<template>
    <div class="md-switch md-theme-default" :class="{'md-checked': isActive}" @click="set">
        <div class="md-switch-container">
            <div class="md-switch-thumb">
                <div class="md-ripple">
                    <input type="checkbox" value="true">
                </div>
            </div>
        </div>
        <label for="md-switch-jd8c5sika" class="md-switch-label"><slot /></label>
    </div>
</template>

<script>
export default {
     props: ["modelValue"],
    emits: ['update:modelValue'],
    data() {
        return {
            isActive: this.modelValue == true,
        }
    },
    computed: {
     
    },
    methods:{  
        set() {
            this.isActive = !this.isActive;
            this.$emit('update:modelValue', this.isActive);
            this.$emit('change');
            
        }
    }
}
</script>

<style lang="scss" scoped>
.md-switch.md-theme-default.md-checked .md-switch-container {
    background-color: rgba(255,82,82,.38);
    background-color: var(--md-theme-default-accent-on-,rgba(255,82,82,.38))
}

.md-switch.md-theme-default.md-checked .md-switch-thumb {
    background-color: #ff5252;
    background-color: var(--md-theme-default-accent,#ff5252)
}

.md-switch.md-theme-default.md-checked .md-ripple {
    color: #ff5252;
    color: var(--md-theme-default-accent,#ff5252)
}

.md-switch.md-theme-default.md-checked.md-primary .md-switch-container {
    background-color: rgba(68,138,255,.38);
    background-color: var(--md-theme-default-primary-on-,rgba(68,138,255,.38))
}

.md-switch.md-theme-default.md-checked.md-primary .md-switch-thumb {
    background-color: #448aff;
    background-color: var(--md-theme-default-primary,#448aff)
}

.md-switch.md-theme-default.md-checked.md-primary .md-ripple {
    color: #448aff;
    color: var(--md-theme-default-primary,#448aff)
}

.md-switch.md-theme-default .md-switch-container {
    background-color: rgba(0,0,0,.38)
}

.md-switch.md-theme-default .md-switch-thumb {
    background-color: #f5f5f5;
    background-color: var(--md-theme-default-switchvariant,#f5f5f5)
}

.md-switch.md-theme-default.md-disabled .md-switch-container {
    background-color: rgba(0,0,0,.12)
}

.md-switch.md-theme-default.md-disabled .md-switch-thumb {
    background-color: #bdbdbd;
    background-color: var(--md-theme-default-switchvariant,#bdbdbd)
}





.md-switch {
    width: auto;
    margin: 16px 16px 16px 0;
    display: inline-flex;
    position: relative
}

.md-switch:not(.md-disabled),.md-switch:not(.md-disabled) .md-switch-label {
    cursor: pointer
}

.md-switch .md-switch-container {
    width: 34px;
    min-width: 34px;
    height: 14px;
    margin: 3px 0;
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 14px;
    transition: .4s cubic-bezier(.25,.8,.25,1)
}

.md-switch .md-switch-thumb {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
    width: 20px;
    height: 20px;
    position: relative;
    border-radius: 50%;
    transition: .4s cubic-bezier(.25,.8,.25,1)
}

.md-switch .md-switch-thumb:before {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 6;
    transform: translate(-50%,-50%);
    content: " "
}

.md-switch .md-switch-thumb .md-ripple {
    width: 48px!important;
    height: 48px!important;
    top: 50%!important;
    left: 50%!important;
    position: absolute;
    transform: translate(-50%,-50%);
    border-radius: 50%
}

.md-switch .md-switch-thumb input {
    position: absolute;
    left: -999em
}

.md-switch .md-switch-label {
    height: 20px;
    padding-left: 16px;
    position: relative;
    line-height: 20px
}

.md-switch.md-checked .md-switch-thumb {
    transform: translate3d(15px,0,0)
}

.md-switch.md-required label:after {
    position: absolute;
    top: 2px;
    right: 0;
    transform: translateX(calc(100% + 2px));
    content: "*";
    line-height: 1em;
    vertical-align: top
}




</style>