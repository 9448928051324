<template>
    <div class="wrapper" v-if="loaded">
        <div id="category" class="section centered">
            <div id="daily" class="section" v-if="names">
                <a class="section_title" :style="'--color:'+plan_details.color">
                    <span>הנציב היומי</span>
                </a>
                <Carusel delay="5000" :hideCandle="true" :natziv="true" :date="heb_date" :maxInSlide="1" promotionText="להצלחה ולכל הישועות" :numPerView="1"
                effect="fade" :items="names" />
            </div>
            <div :key="plan_id">

                <a class="section_title" :style="'--color:'+plan_details.color">
                    <span>{{plan_details.names.length}} {{plan_details.name}} עד כה</span>
                </a>
                <!-- <Carusel :maxInSlide="2" :numPerView="4" :items="plan_details.names" /> -->
                <div class="grid"  :style="'--color:'+plan_details.color">
                    <div class="itemInner" v-for="(innerItem,z) in compNames" :key="z" @click="search(innerItem.link)">
                        <template v-if="plan_id == 4">
                            <div class="text">
                                <h3 v-text="innerItem.donor_name"></h3>
                            </div>
                        </template>
                        <template v-else>
                          <div v-if="innerItem.category == 3" class="candle"></div>
                            <div class="text">
                            <div class="small_title">{{ (innerItem.category_text) }}</div>
                            <h3 v-text="innerItem.name"></h3>
                            <h4 v-text='(date && date != "" ? date : `נתרם ע"י ` + innerItem.donor_name )'></h4>
                            </div>  
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <button class="btn" @click="showAll = !showAll" v-if="plan_details.names.length >  compMaxNames">{{(!showAll) ? 'הצג הכל' : 'הצג פחות'}}</button>
    </div>
</template>

<script>
// import { Swiper, SwiperSlide } from "swiper/vue";
// import "swiper/css";
import Carusel from "@/components/Carusel.vue";

export default {
	components: {
		// Swiper,
		// SwiperSlide,
		Carusel,
	},
	data() {
		return {
			plan_id: this.$route.params.id,
			plan_details: {},
			names: [],
			loaded: false,
			showAll: false,
		};
	},
	computed: {
		compNames() {
			if (this.showAll) return this.plan_details.names;
			else return this.plan_details.names.slice(0, this.compMaxNames);
		},
		compMaxNames() {
			if (this.showAll) return 9999999999;
			else return 16;
		},
	},
	methods: {
		categoryName(id) {
			let category_name = "";
			this.$store.categories.forEach((cat) => {
				if (+cat.id == +id) category_name = cat.name;
			});
			return category_name;
		},
		search(link) {
			this.$router.push(link);
		},
	},

	mounted() {
        if(this.$store.plans && this.$store.plans.length > 0)
        {
            this.$store.plans.forEach((plan) => {
                if (plan.id == +this.plan_id) this.plan_details = plan;
            });
        }
        this.names = this.$store.today_names.names;

        this.loaded = true;
	
	},
    watch: {
        '$route.params.id'() {
            this.plan_id = this.$route.params.id
            if(this.$store.plans && this.$store.plans.length > 0)
            {
                this.$store.plans.forEach((plan) => {
                    if (plan.id == +this.plan_id) this.plan_details = plan;
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.wrapper {
    &{display: flex;justify-content: center;align-items: center;gap: 30px;flex-direction: column;}
    .grid {
        &{position: relative;display: grid;grid-template-columns: repeat(4,1fr); gap: 15px;}
        @media (max-width:600px) {grid-template-columns: repeat(2,1fr);overflow: hidden;padding: 5px;gap: 10px;margin-bottom:20px }
        &::after{ content: "";position: absolute;z-index: 0; top: -5%;right: -3%;width: 106%;height: 110%;background: var(--color) !important; border-radius: 10px;}
        .itemInner{
            &{background: #fff;color: #000;padding: 10px 20px;border-radius: 10px;margin-bottom: 20px;display: flex;gap:15px;z-index: 1;}
            @media (max-width:600px) {margin-bottom: 0;flex-direction: column}
            .text{
                &{width: 100%;}
                h3{margin-bottom: 8px;border-bottom: 2px solid #C2C2C2;font-size: 25px;margin: 10px 0 5px;display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;
		overflow: hidden;line-height: 1;}
                h4{    font-size: 16px;font-weight: 400;}
            }
            .candle{background-image: url(../assets/candle.png);height: 40px;width: 40px;background-repeat: no-repeat;background-position: center;background-size: contain;}
        }
    }
    .section_title { text-align: start !important;padding-bottom: 10px;color:white;   text-shadow: 1px 0px 2px black; span::before { top: 72%; border-color:var(--color) !important}}
    .btn {font-size:25px;font-weight: bold;}
}

</style>