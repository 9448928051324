import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import globalMixin from "./globalMixin";
import $bus from "./eventbus";
import directives from "./directives/";

import "./css/general.scss";
import "./css/app.scss";
import "./css/tooltip.scss";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

window.api_url = "https://hdbmemorial.newdev.wizzo.co.il/api_vue/";

const app = createApp({
	extends: App,
})
	.use(store)
	.use(router);

app.config.devtools = false;


app.mixin(globalMixin);
directives(app);

import Banner from "./components/banner.vue";
app.component("Banner", Banner);

import Popup from "./components/popup.vue";
app.component("Popup", Popup);

import Switch from "./components/inputs/switch.vue";
app.component("md-switch", Switch);

import Checkbox from "./components/inputs/checkbox.vue";
app.component("md-checkbox", Checkbox);

import Input from "./components/inputs/input.vue";
app.component("md-input", Input);

import MdRadio from "./components/inputs/MdRadio.vue";
app.component("md-radio", MdRadio);

import progressBar from "./components/inputs/progressBar.vue";
app.component("md-progress-bar", progressBar);

import Tooltip from "./components/Tooltip.vue";
app.component("Tooltip", Tooltip);

import wzselect from "@/components/inputs/wzselect.vue";
app.component("wzselect", wzselect);

import axios from "axios";
import VueAxios from "vue-axios";
app.use(VueAxios, axios);

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
app.use(VueSweetalert2);

// import Toasted from 'vue-toasted';
// app.use(Toasted)

/*
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
//import 'vue-material/dist/theme/default-dark.css'
app.use(VueMaterial)
*/

app.config.globalProperties.$bus = $bus;

app.mount("#app");
