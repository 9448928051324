<template>
	<div class="wrapper" v-if="loaded">
		<div class="forms_wrapper">
			<form v-for="(item,i) in names" :key="i">
				<div class="title" v-text="(names && names.length > 1 ? 'הנצחה ' + (i+1) : 'הנצחה')"></div>
				<div class="input_wrapper">
					<input placeholder="" type="text" name="name" v-model="item.name_pay"  />
					<label>שם המנציח/ה:</label>
				</div>
				<label class="checkbox">
					<input placeholder="" type="checkbox" v-model="item.anonymous">
					אנונימי		
				</label>
				<div class="input_wrapper">
					<input placeholder=""	type="text"	name="name"	v-model="item.name"	maxlength="20"/>
					<label>שם המונצח/ת:</label>
				</div>
				<label class="small_title">עבור מה תקדישו את ההנצחה?</label>
				<div class="input_wrapper">
					<wzselectVue :options="$store.categories.map(row => { return { key: row.id, val: row.name } })" 
					:settings="{placeholder:'זיווג,פרנסה וכו',width: is_mobile() ? '100%' :'auto'}" v-model="item.category" />
				</div>
				<div class="input_wrapper" v-if="item.category == 7"> 
					<input placeholder=""	type="text"	name="name" maxlength="15"	v-model="item.free_text"/>
					<label>הקדשה: </label>
				</div>
				<label class="small_title">בחרו תאריך להנצחת היום</label>
				<div class="input_wrapper date">
					<div class="date_wrapper">
						<wzselectVue
							:options="days.map(row => { return { key: row, val: row } })" 
							:settings="{ label: 'יום', width: 'auto'}"
							v-model="item.day"
						/>
						<wzselectVue
							:options="months.map(row => { return { key: row, val: row } })" 
							:settings="{ label: ' חודש', width: 'auto'}"
							v-model="item.month"
						/>
					</div>
				</div>
				<!-- <div class="share">
					<checkboxVue
						:value="shareMail"
						@input="$emit('update:shareMail', $event.target.value)"
						>מייל</checkboxVue
					>
					<checkboxVue
					:value="shareWhatsapp"
						@input="$emit('update:shareWhatsapp', $event.target.value)"
						>וואצאפ
					</checkboxVue>
				</div> -->
			</form>
		</div>
		<Teleport to="#next_step_btn_wrapper">
			<button type="submit" class="btn" @click="send()">שמירת הפרטים ומעבר לשלב הבא</button>
		</Teleport>
	</div>
</template>

<script>
// import checkboxVue from "./inputs/checkbox.vue";
import wzselectVue from "./inputs/wzselect.vue";
export default {
	components: { wzselectVue },
	props: { planData:{} },
	data() {
		let dataJSON = this.getDataJSON();
		return {
			names: [],
			days:["א","ב","ג","ד","ה","ו","ז","ח","ט","י",'יא','יב','יג','יד','טו','טז','יז','יח','יט','כ','כא','כב','כג','כד','כה','כו','כז','כח','כט','ל'],
			months:["תשרי","חשוון","כסלו","טבת","שבט","אדר א","אדר ב","ניסן","אייר","סיוון","תמוז","אב","אלול"],
			loaded:false,
			form: {
				name: dataJSON.name,
				day: dataJSON.day,
				month: dataJSON.month,
			}
		};
	},
	mounted(){
			if(this.planData.id == 4)
			{
				this.$emit("next_step");
			}
			
			const savedNames = this.getNamesJson();
			if(savedNames && savedNames.length > 0) this.names = savedNames;
			else {
				for(let i = 0;i < this.planData.num_names; i ++)
				{
					this.names.push({
						name_pay:this.form.name,
						name:'',
						date:'',
						anonymous: false,
						category:'1',
						month:(this.form.month && this.form.month != '' ? this.form.month : 'תשרי'),
						day:(this.form.day && this.form.day != '' ? this.form.day  : 'א')
					}); 
				}
			}
			
		

		// console.log(this.names)
		this.loaded = true;
	},
	methods: {
		send() {

			let done = 0;
			this.names.forEach(name => {
				if (name.name_pay == "" ||name.name == "" ||name.category == "" ||name.day == "" ||name.month == "")
				{
					this.alert("נא למלא את כל הפרטים הנדרשים");
					done++;
				}
				
			})
			if (done == 0) this.$emit("save_memorial_name",this.names);
		},
	},
};
</script>

<style lang="scss" scoped>
	.forms_wrapper {
		&{display: flex;gap:20px;justify-content: center; align-items: center;}
		@media (min-width: 600px) {  }
		@media (max-width: 600px) { flex-direction: column; width: 100%;}
	}
    form {
		&{display: flex;flex-direction: column;justify-content: space-between;gap:15px;align-items: center;background: white;width: fit-content;
        border-radius: 30px;}
		@media (min-width: 600px) { padding: 50px; }
		@media (max-width: 600px) { padding: 20px;width: 100%; }
		.small_title{font-size: 20px;margin-bottom: -10px;}
		
        .title {font-size: 40px;text-align: center;}
        .share {display: flex;gap:10px;justify-content: center; align-items: center;}
    }
    .btn {font-size: 24px;background: #E70F57;
		// @media (max-width: 600px) {margin: 20px 0;}
		@media (min-width: 600px) {padding: 20px;height: auto;}
	}
	.checkbox {align-self: flex-start;padding-right: 10px;font-size: 22px;}

	.input_wrapper.date{
		@media (max-width: 600px) {justify-content: center;}
		@media (min-width: 600px) {}
	}
	
</style>