<template>
    <div class="natziv_wrap" v-if="items && items.length > 0">
			<div class="natziv"	v-for="(name, index) in items"	:key="index">
				<div class="title">{{ getCardTitle(name.plan_id) }}</div>
				<div class="text">{{ getCardText(name.plan_id) }}</div>
				<div class="memo_date">{{ name.memorial_date }}</div>
				<div class="category">מוקדשות {{ (name.category_text) }} עבור</div>
				<div class="get_name">{{ name.name }}</div>
				<div class="candle" v-if="name.category == 3"></div>
				<share v-if="name.plan_id != 4" :link="name.full_link"/>
			</div>
		</div>
</template>
<script>
import share from "@/components/share.vue"

export default {
    components:{ share },
    props:{items: Array}
}
</script>

<style lang="scss" scoped>
.natziv_wrap{
		&{display: flex;margin-top: 50px;}
		@media (min-width: 600px) { gap:30px; }
		@media (max-width: 600px) { flex-direction: column;gap:20px; width: 90%; }
		.natziv {
			&{display: flex;gap:15px;flex-direction: column;align-items: center;text-align: center;background:#4F5861;padding: 30px 60px;border-radius: 10px;}
			*{font-size:20px; color: white}
			.title {font-size: 60px; font-weight: bold;}
			.text {max-width: 170px;}
			.memo_date {font-size:40px}
			.get_name {font-size:40px;line-height: 1;}
			.candle {background: url("../assets/candle.png");width: 30px; height: 30px;background-size: cover;}
		}
	}

</style>