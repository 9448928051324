<template>
	<form @submit.prevent="send()" v-if="loaded">
		<div class="title">טופס מילוי פרטים</div>
		<div class="inputs">
			<div class="input_wrapper">
				<input placeholder="" type="text" name="name" v-model="form.name" @input="updateName" required/>
				<label>שם: </label>
			</div>
			<div class="input_wrapper">
				<input placeholder="" type="email" name="email" v-model="form.email" @change="updateEmail" required/>
				<label>מייל: </label>
			</div>
			<div class="input_wrapper">
				<input placeholder="" type="text" name="phone" v-model="form.phone" pattern="[0-9]{9,}" @input="updatePhone" required/>
				<label>טלפון: </label>
			</div>
			<div class="input_wrapper">
				<input placeholder="" type="text" name="id" v-model="form.idCard" pattern="[0-9]{9,}" @input="updateId" required/>
				<label>תעודת זהות: </label>
			</div>
			<div class="input_wrapper">
				<input placeholder="" type="text" name="city" v-model="form.city" @input="updateCity" required/>
				<label>מקום מגורים: </label>
			</div>
		</div>
		<div>* תורם יקר - חשוב להזין כתובת אימייל תקינה לקבלת קבלה וקישור להנצחה</div>
		<div class="forms_wrapper" >
			<label class="small_title">בחרו תאריך להנצחת היום</label>
			<div class="input_wrapper date">
				<div class="date_wrapper">
					<wzselectVue :options="days.map(row => { return { key: row, val: row } })"  :settings="{ label: 'יום', width: 'auto'}" v-model="form.day" />
					<wzselectVue :options="months.map(row => { return { key: row, val: row } })"  :settings="{ label: ' חודש', width: 'auto'}" v-model="form.month"  />
				</div>
			</div>
		</div>
		<button type="submit" class="btn">מעבר לשלב הבא</button>
	</form>
</template>

<script>
import wzselectVue from "./inputs/wzselect.vue";

export default {
	props: {
		name: String,
		id_card: String,
		phone: String,
		email: String,
		address: String,
		plan_id: Number
	},
	components:{wzselectVue},
	emits: ["update:name", "update:id_card", "update:phone", "update:email","update:day", "nextStep"],
	data(){

		let dataJSON = this.getDataJSON();

		return{
			loaded:false,
			day:"א",
			month:"תשרי",
			name_form:'',
			email_form:'',
			phone_form:'',
			id_form:'',
			city_form:'',
			day_form:'א',
			month_form:'תשרי',
			days:["א","ב","ג","ד","ה","ו","ז","ח","ט","י",'יא','יב','יג','יד','טו','טז','יז','יח','יט','כ','כא','כב','כג','כד','כה','כו','כז','כח','כט','ל'],
			months:["תשרי","חשוון","כסלו","טבת","שבט","אדר א","אדר ב","ניסן","אייר","סיוון","תמוז","אב","אלול"],
			form: {
				name: dataJSON.name,
				email: dataJSON.email,
				idCard: dataJSON.idCard,
				phone: dataJSON.phone,
				city: dataJSON.city,
				day: dataJSON.day,
				month: dataJSON.month,
			}
		}
	},
	mounted(){

		for (let i in this.days)
		{
			if(this.days[i] == this.$store.heb_day && (!this.getDataJSON().day || this.getDataJSON().day == ''))
			{
				this.form.day = this.days[i];
			}
		}
		for (let i in this.months)
		{
			if(this.months[i] == this.$store.heb_month && (!this.getDataJSON().month || this.getDataJSON().month == ''))
			{
				this.form.month = this.months[i];
			}
		}
		this.loaded = true;
	},
	computed: {
	
	
	},
	methods: {
		
		
		change(data){
			this.$store.form.day = data;
		},
		changeMonth(data){
			this.$store.form.month = data;
		},
		checkId(id) {
			id = String(id).trim();
			if (id.length !== 9 || isNaN(id) || id == '000000000') return false;
			id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
			return Array.from(id, Number).reduce((counter, digit, i) => {
					const step = digit * ((i % 2) + 1);
					return counter + (step > 9 ? step - 9 : step);
				}) % 10 === 0;
		},
		checkEmail(email) {
			const pattern = /^[\w.-]+@[\w.-]+\.\w+$/;
			return pattern.test(email);
		},
		send() {
			this.saveDataJson(this.form);
			if (!this.checkEmail(this.form.email))
			{
				
				this.alert('אימייל לא תקין');
				return false;
			}
			if (!this.checkId(this.form.idCard)) {
				this.alert('מספר תעודת זהות לא תקין');
				return false;
			} 
			if (this.form.phone == "" || this.form.city == "" || this.form.phone == "" )
			{
				this.alert("נא למלא את כל הפרטים הנדרשים");
			} 
			else this.$emit("nextStep");
		},
	},
};



</script>

<style lang="scss" scoped>
    form {
        .title {font-size: 40px;}
        .btn {font-size: 24px;margin-top: 20px;}
    }
	.inputs {
		&{display: flex;flex-direction: column;gap:10px;width: 100%;}
		@media (max-width: 600px) {align-items: center;}
		@media (min-width: 600px) {max-width: 300px;}
		.input_wrapper {position: relative;
			&:focus-within label,
			input:not(:placeholder-shown) + label{transform: translateY(-50%);top: 0;background-color: #fff;}
			label {position: absolute;transition: all .25s;right: 20px;
				@media (max-width: 600px) {flex: 0.5;}
				@media (min-width: 600px) {}
			}


		}

	}


	.forms_wrapper{
		@media (max-width: 600px) {width: 100%;}
		@media (min-width: 600px) {}

		.small_title{
			@media (max-width: 600px) {margin-bottom: 5px;display: block;padding: 0 15px;}
			@media (min-width: 600px) {}
		}

		.date_wrapper{
			@media (max-width: 600px) {display: flex;width: 100%;}
			@media (min-width: 600px) {}
			
			&>*{
				@media (max-width: 600px) {flex: 1;}
				@media (min-width: 600px) {}
			}
		}
	}
</style>