<template>
  <div class="md-radio" :class="[radioClasses]"  @click.stop="toggleCheck">
    <div class="md-radio-container">
      <!-- <MdRipple md-centered :v-model:md-active="rippleActive" :md-disabled="disabled">
        <input type="radio" v-bind="{ name, disabled, required, modelValue, checked: isSelected }">
      </MdRipple> -->
    </div>

    <label class="md-radio-label" v-if="$slots.default"><slot /></label>
  </div>
</template>

<script>
  // import MdRipple from './MdRipple.vue'

  export default {
    // components: { MdRipple },
    emits: ['update:modelValue'],

    props: {
      value: [String, Number, Boolean, Object],
      modelValue: {
        type: [String, Number, Boolean, Object],
        default: 'on'
      },
      name: [String, Number],
      required: Boolean,
      disabled: Boolean
    },
    model: {
      prop: 'model',
      event: 'change'
    },
    data: () => ({
      rippleActive: false
    }),
    computed: {
      isSelected () {
        return this.value === this.modelValue
      },
      radioClasses () {
        return {
          'md-theme-default': true,
          'md-checked': this.isSelected,
          'md-disabled': this.disabled,
          'md-required': this.required
        }
      }
    },
    methods: {
      toggleCheck () {
        if (!this.disabled) {
          this.rippleActive = true;
          
          this.$emit('update:modelValue', this.value)
        }
      }
    }
  }
</script>

<style lang="scss">

  $md-radio-size: 20px;
  $md-radio-touch-size: 48px;

  .md-radio {
    width: auto;
    margin: 16px 16px 16px 0;
    display: inline-flex;
    position: relative;

    &:not(.md-disabled) {
      cursor: pointer;

      .md-radio-label {
        cursor: pointer;
      }
    }

    .md-radio-container {
      width: $md-radio-size;
      min-width: $md-radio-size;
      height: $md-radio-size;
      position: relative;
      border: 2px solid rgba(0,0,0,.54);
      border-radius: 50%;
      transition: .4s cubic-bezier(.25,.8,.25,1);

      &:focus {
        outline: none;
      }

      &:before,
      &:after {
        position: absolute;
        transition: .4s cubic-bezier(.55,0,.55,.2);
        content: " ";
      }

      &:before {
        width: 48px;
        height: 48px;
        top: 50%;
        left: 50%;
        z-index: 11;
        border-radius: 50%;
        transform: translate(-50%, -50%);
      }

      &:after {
        position: absolute;
        top: 3px;
        right: 3px;
        bottom: 3px;
        left: 3px;
        border-radius: 50%;
        opacity: 0;
        transform: scale3D(.38, .38, 1);
        content: " ";
      }

      .md-ripple {
        width: 48px!important;
        height: 48px!important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }

      input {
        position: absolute;
        left: -999em;
      }
    }

    .md-radio-label {
      height: 20px;
      padding-left: 16px;
      position: relative;
      line-height: 20px;
    }
  }

  .md-radio.md-checked {
    .md-radio-container {
      &:after {
        opacity: 1;
        transform: scale3D(1, 1, 1);
        transition: .4s cubic-bezier(.25,.8,.25,1);
      }
    }
  }

  .md-radio.md-required {
    label:after {
      position: absolute;
      top: 2px;
      right: 0;
      transform: translateX(calc(100% + 2px));
      content: "*";
      line-height: 1em;
      vertical-align: top;
    }
  }

  
.md-checkbox.md-theme-default.md-checked .md-checkbox-container { background-color: #dd1a7b; border-color:#dd1a7b; }
.md-radio.md-theme-default.md-checked .md-radio-container { border-color:#dd1a7b; }
.md-radio.md-theme-default.md-checked .md-radio-container:after { background-color: #dd1a7b;}
</style>
