<template>
	<div class="wrapper">
		<div class="sum">
			<button class="clean_btn btn_go_back" @click.stop.prevent="$emit('go_back')">→ חזור אחורה</button>
			<div class="inner_wrapper">
				<div class="name"><span>שם המנציח:</span> {{ form.name }}</div>
				<!-- <div><span>מסלול:</span> {{ plandata.plan.name }}</div> -->
				<wzselectVue :options="$store.plans.map(row => { return { key: row.id, val: row.name } })" :disabled="isPaymentStep" :settings="{ label: 'חבילה', width:is_mobile() ? '100%' : '200px'}" v-model="plan_id" @change="change_plan"  />
				<div class="pay">
					<wzselectVue :options="payments_array.map(row => { return { key: row.val, val: row.val } })" :disabled="isPaymentStep" :settings="{ label: 'מספר תשלומים', width: is_mobile() ? '100%' : '200px'}" v-model="select_ttl_payments" @change="updateTotalPayments" />
					<p> <span class="title">סך תשלום:</span> {{ totalPaymentInfo.monthlyPayment }}</p>
					<p> <span class="title">סך התחייבות:</span> {{ totalPaymentInfo.total}} </p>
				</div>
				<section id="next_step_btn_wrapper">
					<button v-if="step !== 2 && step !== 4" @click="send()" class="btn">מעבר לעמוד התרומה ←</button>
				</section>
			</div>
		</div>
	</div>
</template>

<script>
import wzselectVue from "./inputs/wzselect.vue";
export default {
	emits: ["go_back", "saveForm", "plan_changed"],
	props: ["plandata", "step"],
    components:{wzselectVue},
    data(){
        let dataJSON = this.getDataJSON();
        let planJSON = this.getPlanJSON();
		return{
			loaded:false,
			plan_id: planJSON.id,
			select_ttl_payments: planJSON.num_payments || 18,
			form: {
				name: dataJSON.name
			},
			plan: planJSON
			
        }
    },
	computed: {
		num_payments(){
			return this.plandata.plan.num_payments || +this.plandata.plan.months || 18;
		},
		payments_array(){

			let Array = [];
			for (let index = 0; index < this.num_payments; index++)
			{
				Array.push({val:(index+1)});
			}
			return Array;
		},
		isPaymentStep(){
			return +this.step === 4;
		},
		totalPaymentInfo(){
			const planPaymentTotal = this.plandata.plan.price * this.plandata.plan.months;
			const monthlyPayment = (planPaymentTotal / this.select_ttl_payments).toFixed(2);
			return {monthlyPayment, total: planPaymentTotal}
		}
	},
	methods: {
		change_plan(data) {
			const plan = this.getPlanJSON();
			plan.id = +data;
			const selectedPlan = this.$store.plans.find(currPlan=>+currPlan.id === +plan.id);
			plan.num_payments = +selectedPlan.months;
			this.savePlanJson(plan);
			this.select_ttl_payments = plan.num_payments;

			const isPaymentStep = +this.step === 4;
			if(isPaymentStep) this.$emit("saveForm");
			this.$emit("plan_changed",data, !isPaymentStep);
		},
		send() {
			let form = {};
			form.id = this.plandata.plan.id;
			form.num_payments = this.select_ttl_payments;
			this.savePlanJson(form);
			this.$emit("saveForm");
		},
		updateTotalPayments(selectTotalPayments){
			const plan = this.getPlanJSON();
			plan.num_payments = selectTotalPayments;
			this.savePlanJson(plan);
			if(+this.step === 4) this.$emit("saveForm");
		}
	},
};
</script>

<style lang="scss" scoped>
.wrapper {
		&{margin: 20px 0 50px !important;}
	
	#next_step_btn_wrapper{
		@media (max-width: 600px) {position: fixed;bottom: 0;left: 0;right: 0;display: flex;justify-content: center;padding: 10px;z-index: 99;background-color: #fff;}
		@media (min-width: 600px) {}

		&:empty{display: none;}
	}

	.btn_go_back{font-size: 20px;}
    .sum {
        &{background: white;border-radius: 30px;}
        @media (min-width: 600px) { padding: 30px; }
		@media (max-width: 600px) { padding: 20px;flex-direction: column; width: 100%; align-items: center; }
		.name { display: flex; align-items: center;  justify-content: center; }
		.pay { display: flex; flex-direction: column; gap: 7px; border-radius: 30px; padding-block: 10px 25px;
			@media (max-width: 600px) {width: 100%;align-items: center;}
			@media (min-width: 600px) {}
			.wzselect {display: block; border-inline: none; border-top: none; border-radius: 0; padding-block: 0px; }
			p { height: 1em; 
				span {color: #9E642E; font-weight: 900;}
			}
		}
		.btn {background: #E70F57 !important;}
		.wzselect-inp {
			&::after {display: none;}
		}
		.inner_wrapper{border-radius: 10px;display: flex;justify-content: space-between;align-items: self-start; gap: 15px;background: white;
				@media (max-width: 600px) { padding: 20px;flex-direction: column; width: 100%; align-items: center;}
			div {
				border: 1px solid rgba(0, 0, 0, 0.25);border-radius: 50px;padding-inline:20px;font-size:25px; min-height: 52px; 
				span {font-weight: bold;}
			}
		}
        .btn {background: #081421;font-size:25px;height: auto;line-height: 20px;
			@media (max-width: 600px) {height: 38px;}
			@media (min-width: 600px) {padding:20px;}
		}
    }
}
</style>