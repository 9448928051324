<template>
  <div class="about centered">
    <div class="section_title"><span>סה”כ {{sumPartners}} שותפים בפרויקט  </span></div>
    <div class="text_wrap">
        <div class="text">
            <div v-html="$store.words.about_text"></div>
            <div class="btn_wrap">
                <router-link to="/plans" class="btn">לשותפות בפרוייקט</router-link>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
	data() {
		return {
			sumPartners: 0,
		};
	},
	mounted() {
        this.api({ action: 'names/results', data: {}}, (data) =>{
            this.sumPartners = data.data.num;
             this.loaded = true;
        })
        
	
	},
};
</script>

<style lang="scss" scoped>
.section_title {
    &{text-align: start !important;padding-bottom: 10px;color:white; text-shadow: 1px 0px 2px black;}
    span::before { top: 72%; border-color:#dd1a7b !important}
}
.text_wrap {
    &{background: #dd1a7b ;}
    .text {
        &{display: flex;flex-direction: column;justify-content: center; align-content: center;background: white;line-height: 1.5;
        font-size: 30px; border-radius: 10px;padding: 50px;}
        .btn_wrap {
            &{padding-top: 30px;display: flex;justify-content: center;align-items: center;}
            .btn {font-size: 42px;height: auto;padding: 10px 20px}
        }
    }
}
</style>