<template>
	<div class="centered wrapper" v-if="loaded">
		<div class="thanks">
			<div class="title">אשריך!</div>
			<div class="text">זכית בזכות ההיסטורית, והינך <b>{{ getCardTitleEnd(plandata.plan.id) }}</b>
			של מהפכת הדיגיטל היהודית העולמית
			</div>
			<p class="invoice_text" v-html="get_word('invoice_sent_text')"></p>
		</div>
		<div class="natziv_wrap" v-if="items && items.length > 0">
			<div class="natziv"	v-for="(name, index) in items"	:key="index">
				<div class="title">{{ getCardTitle(name.plan_id) }}</div>
				<div class="text">{{ getCardText(name.plan_id) }}</div>
				<div class="memo_date">{{ name.memorial_date }}</div>
				<div class="category">מוקדשות {{ (name.category_text) }} עבור</div>
				<div class="get_name">{{ name.name }}</div>
				<div class="candle" v-if="name.category == 3"></div>
				<share v-if="name.plan_id != 4" :link="name.full_link"/>
			</div>
		</div>
		<router-link to="/" class="btn"> לעמוד כל ההנצחות ← </router-link>
	</div>
</template>


<script>
import share from "@/components/share.vue"
export default {
	props: ["plandata","order_id"],
	components: {share },
	data() {
		return {
			items:[],
			loaded: false,
		};
	},
	computed: {
	
	},
	
	mounted() {
		if(this.order_id && this.order_id > 0)
		{
			this.api({ action: 'names/results', data: {order_id:this.order_id}}, (data) =>{
				this.items = data.data.items;
				this.loaded = true;
			});
		}

	},
};
</script>
<style lang="scss" scoped>
    .thanks {
        &{background: linear-gradient(91deg, rgba(13, 230, 175, 0.64) -41.14%, #0DE6AF 83.68%, rgba(13, 230, 175, 0.6) 140.37%);
        border-radius: 8px;display: flex;flex-direction: column;justify-content: center;align-items: center; text-align: center; padding: 40px;
        color:#06091EEF;margin-top: 40px;width: 100%;}
        @media (min-width: 600px) { padding: 40px; }
		@media (max-width: 600px) { padding: 20px; }
		.title {
			&{font-weight: bold;}
			@media (min-width: 600px) { font-size: 60px; }
			@media (max-width: 600px) { font-size: 40px; }
		}
        .text{
			@media (min-width: 600px) { font-size: 40px; }
			@media (max-width: 600px) { font-size: 30px;line-height: 1; }
		}
        
    }
  
	.natziv_wrap{
		&{display: flex;margin-top: 0px;}
		@media (min-width: 600px) { gap:30px; }
		@media (max-width: 600px) { flex-direction: column;gap:20px; width: 90%; }
		.natziv {
			&{display: flex;gap:15px;flex-direction: column;align-items: center;text-align: center;background:#4F5861;padding: 30px 60px;border-radius: 10px;}
			*{font-size:20px; color: white}
			.title {font-size: 60px; font-weight: bold;}
			.text {max-width: 170px;}
			.memo_date {font-size:40px}
			.get_name {font-size:40px;line-height: 1;}
			.candle {background: url("../assets/candle.png");width: 30px; height: 30px;background-size: cover;}
		}
	}
    .btn {
		&{height: 60px;margin: 30px 0 ;font-weight: bold;display: flex;justify-content: center;align-items: center;}
		@media (min-width: 600px) { font-size: 35px; }
		@media (max-width: 600px) { font-size: 22px; }
	}
</style>