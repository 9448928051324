<template>
	<div id="plans">
		<div class="box">
			<h3><b style="color:#dd1a7b;font-size:50px">אל תחמיצו את ההזדמנות הנדירה: זכות "נציב יום"!</b><br>
תאריך קבוע בשנה, בו כל מיליוני הזכויות באתר ובאפליקציית הידברות יוקדשו עבור יקיריכם</h3>
			<div class="items centered">
				<div v-for="item in plans" :key="item.id" class="item">
					<div class="item_wrapper" :style="`--bg-color:${item.color}`">
						<div v-if="item.presents.length > 0" :class="'present_wrap '+'item'+item.id+' '+ (item.hover == true ? 'active' : '')" @mouseenter="item.hover = true" @mouseleave="item.hover = false">
							<div class="present"></div>
						</div>
						<div class="triangle" v-if="item.hover == true"></div>
						<div class="presents" v-if="item.hover == true">
							<div class="presents_wrap" v-for="present in item.presents" :key="present.id">
								<div class="present_icon" v-if="present.pic_parsed != ''" v-text="present.pic_parsed"></div>
								<div class="present_name" v-text="present.name"></div>
							</div>
						</div>
						<div class="name" v-text="item.name"></div>
						<div class="price">
							{{ item.price }}₪
							<span v-text=" item.months > 0? '/למשך ' + item.months + ' חד': 'בתרומה חד פעמית'"></span>
						</div>
						<div class="text_wrap">
							<div class="text" v-html="item.text"></div>
						</div>
						
						<router-link :to="'/plan/' + item.id" class="btn">
							לתרומה
						</router-link>
					</div>
				</div>
			</div>
			<!-- <div class="extra">*שימו לב! מסלול המייסדים ייסגר לתרומות לאחר העלאת האתר, ביום ראשון ח' סיון (28.5) 23:59</div> -->

		</div>
		<!-- <div class="natziv"><span>?</span> נציב יום -</div> -->
	</div>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			plans: [],
		};
	},
	computed: {},
	beforeUnmount() {},
	mounted() {
		this.api({ action: "plans/results", method: "get" }, (data) => {
			for (let i in data.data.items) data.data.items[i].hover = false;
			this.plans = data.data.items;
			this.loaded = true;
		});
	},
	methods: {},
};
</script>

<style lang="scss" scoped>
h3{    color: #fff;text-align: center;font-size: 30px; line-height: 1.2; margin: 10px 0;font-weight: 400;}
.extra{text-align: center;color: #fff;font-size: 25px;margin-top: 20px;}
#plans{
  &{position:relative;display: flex;flex-direction: column; justify-content: center;align-items: center;}
  @media (max-width: 600px) {}
  @media (min-width: 600px) {height: 80vh;}
  .box {width: 90%;position: relative;}
  .natziv {
    &{bottom: 20px;font-size: 30px; color:white;display: flex; align-items: center;}
	@media (min-width: 600px) {position: absolute;}
	@media (max-width: 600px) {}
    span {background: white;color: black;width: 30px;height:30px;display: flex;justify-content: center;align-items: center;
    border-radius: 100%;margin-left: 10px;}
  }
  .items {
    &{display: grid;grid-template-columns: repeat(4,1fr);gap:20px; padding: 0 20px}
	@media (min-width: 600px) {align-items: center;}
	@media (max-width: 600px) {display: flex;flex-direction: column;}
    .item_wrapper{
      &{padding: 20px;background: #fff;display: flex;justify-content: space-between;flex-direction: column;border-radius: 15px;align-items: center;
      text-align: center; min-height: 400px;position: relative;}
      &:not(:hover) {scale: 0.95;transition: 0.3s scale;}
      &:hover {
        box-shadow: 0px 7px 15px var(--bg-color);transition: 0.3s scale;
      }
		.name {line-height: 1;}
      .present_wrap {
        &{position: absolute;background: #E70F57;border-radius: 100%;top:100px;right: 25px;display: flex;justify-content: center;align-items: center;}
		// &.active::after{content: '';position: absolute;z-index: 4;height: 200px ;width: 300px;top: 20px;background-image: url(../assets/tooltip_1.png);
		// 	background-position: center;background-repeat: no-repeat;background-size: contain;}
		// &.item3.active::after,&.item1.active::after{background-image: url(../assets/tooltip_2.png);top: -5px;}
        .present {background: url(../assets/present.svg);background-size: contain;background-repeat: no-repeat;width: 30px;height: 30px;margin: 5px;}
      }
		.triangle {position: absolute;top:145px;right: 30px;width: 0;height: 0;border-left: 15px solid transparent;
			border-right: 15px solid transparent;border-bottom: 15px solid #E70F57;}
		.presents {
			&{position: absolute;top:160px;right: 10px;background: #E70F57;padding:15px;border-radius: 12px;color: #fff;}
			.presents_wrap {
				&{display: flex;justify-content: flex-start;align-items: flex-start;gap:20px}
				.present_icon {width: 15px;height: 15px;}
			}
			
		}
      .name{margin: -20px -20px 0 -20px;padding: 50px;text-align: center;font-size: 45px;font-weight: 500;border-radius: 15px 15px 0 0;
      background:var(--bg-color);margin-bottom: 40px;align-self: stretch;}
      .price{
        &{font-size: 70px;font-weight: 500;padding: 0;}
        span{font-size: 18px;font-weight: 100;color: #ababab;}
      }
      .text_wrap {
         &{width: 100%;display: flex;justify-content: center;padding: 10px 0;}
		.text {max-width: 200px;line-height: 1;
		}
      }
      .extra {color: #081421;font-size: 30px;font-weight: bold;padding:20px 0}
      .btn {background: var(--bg-color);color: #081421 !important;font-size: 28px; font-weight: bold;border-radius: 10px;}
    }
  }
}
</style>