<template>
    <div v-if="loaded">
        <div class="wrapper">
            <div class="natziv_wrap">
                <div class="natziv">
					<div class="title">{{ getCardTitle(name.plan_id) }}</div>
                    <div class="get_name">{{ name.donor_name }}</div>
					<div class="text">{{ getCardText(name.plan_id)  }}</div>
					<div class="memo_date">{{ name.memorial_date }}</div>
				
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
		return {
			id: this.$route.params.id,
			name: {},
			loaded: false,
		};
	},
	computed: {},
	mounted() {
		this.api({ action: "get_partner", data: { id: this.id } }, (data) => {
			this.name = data.data.data;
			this.loaded = true;
		});
    }
}
</script>
<style lang="scss" scoped>
.wrapper{display: flex;justify-content: center;padding: 50px 0;}
.natziv_wrap{
		&{display: flex;margin-top: 50px;}
		@media (min-width: 600px) { gap:30px; }
		@media (max-width: 600px) { flex-direction: column;gap:20px; width: 90%; }
		.natziv {
			&{display: flex;gap:15px;flex-direction: column;align-items: center;text-align: center;background:#4F5861;padding: 40px 60px 30px;border-radius: 10px;position: relative;}
			*{font-size:20px; color: white}
			.title {font-size: 60px; font-weight: bold;}
			.text {max-width: 170px;}
			.donor_name {font-size: 25px;position: absolute;top: 10px;left: 10px;}
			.memo_date {font-size:40px}
			.get_name {font-size:40px;line-height: 1;}
			.candle {background: url("../assets/candle.png");width: 30px; height: 30px;background-size: cover;}
		}
	}
</style>
