<template>
    <div class="share" >
			<div style="color:#000">שתפו:</div>
			<a :href="'http://www.facebook.com/sharer.php?u='+link" class="faceboox">
				<div class="ico"><i class="fab fa-facebook-f"></i></div>
				<!-- <div>פייסבוק</div> -->
			</a>
			<a :href="'https://twitter.com/intent/tweet?url='+link" class="twitter">
				<div class="ico"><i class="fab fa-twitter"></i></div>
				<!-- <div>טוויטר</div> -->
			</a>
			<a :href="'mailto:'+link" class="telegram">
				<div class="ico">
                    <i class="fas fa-envelope"></i>
                    </div>
				<!-- <div>טלגרם</div> -->
			</a>
			<a :href="'whatsapp://send?text='+link" class="whatsapp">
				<div class="ico">
                    <i class="fab fa-whatsapp"></i>
                </div>
				<!-- <div>וואצאפ</div> -->
			</a>
          
		</div>
</template>
<script>
export default {
    props:{link:String},
    data(){
        return{

        }
    }
}
</script>
<style lang="scss" scoped>
  .share {
        &{background: white;border-radius: 8px; display: flex; margin-top: 20px;
        font-weight: bold;color:#06091E;}
		@media (min-width: 600px) { width: 100%;padding: 10px 20px;justify-content: space-around;align-items: center;font-size:20px;gap:10px }
		@media (max-width: 600px) {padding: 10px;gap:10px; justify-content: start;align-items: flex-start;font-size:28px;}
        .faceboox,.twitter,.telegram, .whatsapp {
            &{display: flex;justify-content: center;align-items: center;gap:10px;}
            .ico {
                &{width: 30px;height: 30px;border-radius: 100%;display: flex;justify-content: center;align-items: center;}
                svg {color:white;width: 20px;height: 20px;;}
            }
        }
        .faceboox .ico {background: #425EA7;}
        .twitter .ico {background: #20B0E7;}
        .telegram .ico {
            &{background: linear-gradient(180deg, #1491CC 0%, #2AABEE 4947.92%, #2AABEE 6250%, #2AABEE 10000%);}
            // svg{margin-right: 5px;}
        }
        .whatsapp .ico {background: linear-gradient(0deg, #1FAF38 -9900%, #60D669 100%);}
    }
</style>