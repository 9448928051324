<template>
    <div id="header_wrapper" v-if="loaded">	
		<header>
			<router-link to="/" class="logo"><img  src="@/assets/logo.svg"></router-link>
		</header>
        <div id="menu" v-if="$route.matched[0].props.default.header">
            <div class="centered" style="display:flex;align-items: flex-end;">
                <div id="btn_wrapper">
                    <router-link  v-for="(item,i) in $store.menu"  :to="item.link" class="btn" :key="i">{{item.name}}</router-link>
                </div>
                <div id="partners_btn">
					<router-link to="/plans" class="btn"> לשותפות בפרויקט</router-link>
                </div>
            </div>
			<div class="search centered">
				<form class="search-box" @submit.prevent="search()">
					<input type="text" v-model="searchTerm" placeholder="חיפוש">
					<button @click="search()" type="submit"><i class="fa fa-magnifying-glass"></i></button>
				</form>
			</div>
        </div>

    </div>
</template>
<script>
export default {
	components: {},
	data() {
		return {
			loaded: false,
			searchTerm: '',
			// menu: [{ id: 1, name: "כל ההנצחות", link: "/" }],
		};
	},
	computed: {},
	methods: {
		search(){
			this.$router.push({ path: "/search/"+this.searchTerm})
		}
	},
	beforeUnmount() {},
	mounted() {
		this.$nextTick(() => {
			this.loaded = true;
		});
			
	},
};
</script>

<style lang="scss" scoped>
#header_wrapper{
    #menu{background: #081421;padding-bottom: 10px;}
    &{position: fixed;top: 0;right: 0;left: 0;z-index: 9999;}
    #btn_wrapper{width: 100%;position: relative;
	
      &::after{content: '';background: rgba(255, 255, 255, 0.5);left: 0;right: 0;bottom: 0;height: 2px;    position: absolute; z-index: -1;}
     .btn{background: transparent;font-size: 22px;border-bottom: 2px solid transparent;border-radius: 0;}
      .btn:hover,.btn.active{border-color: #E70F57;}
		
    }
   #partners_btn{width: 200px;
    .btn{border-radius: 0; font-size: 23px;}
   }
   .logo {width: 15%;}
   .search {
		&{display: flex;justify-content: flex-end; align-items: center;padding-bottom: 10px; padding-top: 20px;}
		.search-box {
			&{width: fit-content;position: relative;}
			
			input {
				&{background: transparent;padding-right: 30px;color: white;border-bottom: 2px solid #E70F57;}
				&::placeholder{color:white;};
			}
			button {position: absolute;right: 0;background: transparent;border: none; color:#E70F57;font-size: 22px;}
		}
   }
}
</style>