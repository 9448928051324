<template>
	<div id="home" v-if="loaded">
		<div id="right_block" v-if="!is_mobile()">
			<div class="box">
				<router-link v-if=" partners &&  partners.id"  :to="'/category/' + partners.id" class="section_title" style="--color: #0de6af">
					<span>{{ partners.name }}</span>
				</router-link>
				<div class="list" id="verticalBox" v-if="partners && partners.names">
					<swiper :direction="(!is_mobile() ? 'vertical' : 'horizontal')" :slides-per-view="compSlide" :space-between="0" :loop="true" :modules="modules" :autoplay="{ delay: 2500, disableOnInteraction: false }">
						<swiper-slide v-for="item in partners.names[0]" :key="item.id" class="item">
							<!-- <div class="circle">אש</div> -->
							<div class="text">
								<h3 v-text="item.name"></h3>
								<h4 v-text="item.category_name"></h4>
							</div>
						</swiper-slide>
					</swiper>
				</div>
			</div>
		</div>
		<div id="hp_main">
			<div id="founders" class="section">
				<router-link v-if=" plan_2 &&  plan_2.id" :to="'/category/' + plan_2.id" class="section_title" style="--color: #0de6af">
					<span>{{ plan_2.name }}</span>
				</router-link>
				<Carusel v-if="plan_2 && plan_2.names && plan_2.names.length > 0" :maxInSlide="2" :numPerView="4" :items="plan_2.names" />
			</div>
			<div id="daily" class="section" v-if="$store.today_names.names">
				<a class="section_title" style="--color: #0de6af">
					<span>הנציב היומי</span>
				</a>
				<Carusel :delay="5000" :hideCandle="true" :natziv="true" :date="heb_date" :maxInSlide="1" promotionText="להצלחה ולכל הישועות" :numPerView="1" effect="fade" :items="$store.today_names.names" />
			</div>
			<div id="carusels_wrapper">
				<div class="wrapper">
					<div id="gold" class="section">
						<router-link v-if="plan_1 && plan_1.id" :to="'/category/' + plan_1.id" class="section_title" style="--color: #0de6af">
							<span>{{ plan_1.name }}</span>
						</router-link>
						<Carusel v-if="plan_1 && plan_1.names && plan_1.names.length > 0" :delay="3500" :maxInSlide="2" :numPerView="2" :items="plan_1.names" />
					</div>
				</div>
				<div class="wrapper">
					<div id="silver" class="section">
						<router-link v-if=" plan_3 &&  plan_3.id" :to="'/category/' + plan_3.id" class="section_title" style="--color: #0de6af">
							<span>{{ plan_3.name }}</span>
						</router-link>
						<Carusel v-if="plan_3 && plan_3.names && plan_3.names.length > 0" :delay="3000" :maxInSlide="2" :numPerView="2" :items="plan_3.names" />
					</div>
				</div>
			</div>
		</div>

		<div id="left_block">
			<div class="box">
				<router-link v-if=" partners &&  partners.id" :to="'/category/' + partners.id" class="section_title" style="--color: #0de6af" >
					<span style="color:#000">{{ partners.name }}</span>
				</router-link>
				<div class="list" v-if="partners && partners.names">
					<swiper :direction="(!is_mobile() ? 'vertical' : 'horizontal')" :slides-per-view="compSlide" :space-between="0" :loop="true" :modules="modules" :autoplay="{ delay: 2500, disableOnInteraction: true }" >
						<swiper-slide v-for="item in partners.names[1]" :key="item.id" class="item" >
							<!-- <div class="circle">אש</div> -->
							<div class="text">
								<h3 v-text="item.name"></h3>
								<h4 v-text="item.category_name"></h4>
							</div>
						</swiper-slide>
					</swiper>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";
import Carusel from "@/components/Carusel.vue";

// Import Swiper styles
import "swiper/css";

export default {
	components: {
		Swiper,
		Carusel,
		SwiperSlide,
	},
	data() {
		return {	
			plan_1: [],
			plan_2: [],
			plan_3: [],
			partners: [],
			boxWrapperHeight: 0,
			heb_date: "",
			boxHeight: 70,
			loaded:false,
			dataLoaded:false,
		};
	},
	computed: {
		compSlide() {
			if(this.is_mobile()) return 1;

				return Math.floor(this.boxWrapperHeight / this.boxHeight);
		},
	},
	setup() {
		return {
			modules: [Autoplay],
		};
	},
	beforeUnmount() {},
	mounted() {
		// Stays only for daily natziv - FIX
		this.api({ action: "home_data", method: "get" }, (data) => {
			
			this.heb_date = data.data.heb_date;
			
			this.plan_1 = data.data.plans[0];
			this.plan_2 = data.data.plans[1];
			this.plan_3 = data.data.plans[2];
			this.partners = data.data.partners;
			this.loaded = true;

			
		});
				this.$nextTick(()=>{
				this.calcHight();
			});
	

	},
	methods: {
		calcHight() {
			if(this.is_mobile()) return 1;
			let item = document.getElementById("verticalBox");
			if(item && item != "")
			{
				this.boxWrapperHeight = document.getElementById("verticalBox").clientHeight;
			}
			else{
				setTimeout(()=>{
					this.calcHight();
				},100);
				
			}
			
			
		
		},
	},
};
</script>

<style lang="scss" scoped>
.section_title {    text-shadow: 1px 0px 2px black;color:white; span::before {top: 72%;}}
#home {  
	&{color: #fff; display: flex; gap:40px; padding: 0 25px 25px 25px; min-height: calc(100vh - 80px); align-items: stretch;}
	@media (max-width: 600px) { flex-direction: column;gap:0px}
	#right_block,#right_block, #left_block,#left_block { 
		&{flex:1; z-index: 1;}
		.section_title span::before{border-color: #9E642E;}
		.box { height: 100%;max-height: 90vh;}
	}
	#hp_main { 
		&{flex: 4;max-width: 66.66vw;}
		@media (max-width: 600px) { max-width: 100vw;display: flex;flex-direction: column;gap:10px}
		#founders{
			&{position: relative;}
			@media (max-width: 600px) { margin-top: 20px;}  
			&::after{ 
				&{content: "";position: absolute;right: -10%;z-index: 0;top: 70px;height: 70%;background: #0de6af;width: 120%;background: linear-gradient(180deg, rgba(13, 230, 175, 0.64) 2.36%, #0DE6AF 56.56%, rgba(13, 230, 175, 0.6) 113.12%); border-radius: 10px;}
				@media (max-width: 600px) {  right: 0;width: 90%;;} 
			}
		}
		#carusels_wrapper{
			&{display: flex;gap: 20px;width: 100%;}
			@media (max-width: 600px) { flex-direction: column;gap: 15px;}
			.wrapper{
				&{width: 50%;position: relative;}
				@media (max-width: 600px) { width: 100%;}
			}
			#gold{
				&{position: relative;}
				.section_title span::before{border-color: #B38100;}
				&::after{ 
					&{content: "";position: absolute;left: 30px;z-index: 0;bottom:0px;height: 70%;
					background: linear-gradient(180deg, #b38100 0%, rgba(203, 146, 0, 0.64) 56.56%, #b38100db 85.09%);border-radius: 10px;
					width: 120%;}
					@media (max-width: 600px) {  right: 0;width: 90%;;} 
				} 
				.section_title{color: #fff;text-align: right;}
			}
			#silver{
				&{position: relative;}
				.section_title span::before{border-color: #A3A3A3;}
				&::after{ 
					&{content: "";position: absolute;right: 30px;z-index: 0;bottom: 0px;height: 70%;
					background: linear-gradient(90deg, rgba(157,157,157,0.6306897759103641) 0%, rgba(157,157,157,0.8463760504201681) 50%, rgba(157,157,157,1) 100%);
					border-radius: 10px;width: 120%;}
					@media (max-width: 600px) {  right: 0;width: 90%;;}
				}
				.section_title{color: #fff;text-align: right;}
			}
		}
	}
}
.box { background: #fff; color: var(--color2); filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.1)); border-radius: 7px; padding: 15px;}
.list { 
	&{width: 200px; height: calc(100% - 90px);}
	@media (max-width: 600px) {  width: 100%;;}
}

</style>