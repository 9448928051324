<template>
    <div class="carusel_wrapper">
        <div class="list" ref="verticalBox" >
            <swiper :slides-per-view="(is_mobile() ? 1 : numPerView)" :space-between="20" :autoHeight="is_mobile()" :loop="true" :modules="modules" :autoplay="{  delay: delay,  disableOnInteraction: false}" :effect="effect"> 
              <swiper-slide v-for="(item,i) in itemsComp" :key="i" class="item">
                <div class="itemInner" v-for="(innerItem,z) in item.items" :key="z" @click="link(innerItem.link)"  :class="{natziv: natziv}">
                    <div v-if="innerItem.category == 3" class="candle"></div>
					<h4 v-if="natziv" class="donor" v-text='`נתרם ע"י ` + innerItem.donor_name'></h4>
                    <div class="text">
						<div class="small_title">{{natziv ? 'הנציב היומי מוקדש ' : ''}} {{ innerItem.category_text }} {{innerItem.category != 3 ? 'עבור ' : ''}}</div>
						<h3 v-text="innerItem.name"></h3>
						<h4 v-text='`נתרם ע"י ` + innerItem.donor_name' v-if="!natziv"></h4>
						<h4 v-text="innerItem.date_text" v-else></h4>
                    </div>
                </div>  
              </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, EffectFade, EffectCards } from "swiper";
import "swiper/css/effect-fade";
import "swiper/css/effect-cards";

export default {
	props: {
		natziv: {
			type: Boolean,
			required: false,
		},
		items: {
			type: Array,
			required: true,
		},
		numPerView: {
			type: Number,
			required: true,
			default: 2,
		},
		delay: {
			type: Number,
			required: false,
			default: 2500,
		},
		hideCandle: {
			type: Boolean,
			required: false,
			default: false,
		},
		maxInSlide: {
			type: Number,
			required: false,
			default: 1,
		},
		effect: {
			type: String,
			required: false,
			default: "none",
		},
		promotionText: {
			type: String,
			required: false,
			default: "לעילוי נשמת",
		},
		date: {
			type: String,
			required: false,
			default: "",
		},
	},
	components: {
		Swiper,
		SwiperSlide,
	},
	data() {
		return {
			modules: [Autoplay, EffectFade, EffectCards],
			categories: [],
		};
	},
	computed: {
		itemsComp() {
			let Arr = [];
			let key = 0;

			for (let i in this.items) {
				if (
					Arr[key] &&
					Arr[key].items &&
					Arr[key].items.length >= this.maxInSlide
				)
					key++;
				if (this.isNull(Arr[key]) || this.isNull(Arr[key].items))
					Arr[key] = { items: [] };
				Arr[key].items.push(this.items[i]);
			}

			return Arr;
		},
	},

	beforeUnmount() {},
	mounted() {
		this.$nextTick(() => {
			this.loaded = true;
		});
	},
	methods: {
		link(link) {
			this.$router.push(link);
		},
	},
};
</script>

<style lang="scss" scoped>
#founders{height: 100%;width: 100%;}
.carusel_wrapper{
	&{height: 100%;width: 100%;}
	.itemInner{
		&{background: #fff;color: #000;padding:30px 20px;border-radius: 10px;margin-bottom: 20px;display: flex;gap:15px;align-items: center;
			@media (max-width:600px) {padding:10px;gap:10px;margin-bottom: 10px;height: 150px;display: flex;flex-direction: column;
			justify-content: space-around;}
		}
		@media (max-width:600px) {flex-direction: column}
		&.natziv {
			&{position: relative;}
			.candle{position: absolute;right: 50px;height: 80px;width: 80px;
				@media (max-width:600px) {right: 0px;height: 40px;width: 40px;top:0}
			}
			.donor {position: absolute; top:20px;left: 40px;font-size: 26px;font-weight: 400;color:white;
				@media (max-width:600px) {left: 0;right:0;margin:0 auto;text-align: center;width: 90%;}
			}
		}
		.text{
			&{width: 100%;}
			h3{margin-bottom: 8px;border-bottom: 2px solid #C2C2C2;font-size: 25px;margin: 10px 0 5px;display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;
		overflow: hidden;line-height: 1;}
			h4{font-size: 16px;font-weight: 400;}
		}
		.candle{position: relative;background-image: url(../assets/candle.png);height: 40px;width: 40px;background-repeat: no-repeat;background-position: center;background-size: contain;}
	}
}
</style>