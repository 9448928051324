<template>
	<div id="subscription" v-if="loaded" class="centered">\
        
        <!-- <h2 v-if="name"></h2> -->
        <h2 v-if="names && names.length > 0 && name != ''">  שמות להנצחה  </h2>
        <div class="forms_wrapper" v-if="names && names.length > 0 ">
			<form v-for="(item,i) in names" :key="i">
				<div class="title" v-text="(names && names.length > 1 ? 'הנצחה ' + (i+1) : 'הנצחה')"></div>
				<div class="input_wrapper">
					<label>שם המנציח:</label>
					<input type="text" name="name" v-model="item.name_pay"  />
				</div>
				<label class="checkbox">
					<input type="checkbox" v-model="item.anonymous">
					אנונימי		
				</label>
				<div class="input_wrapper">
					<label>שם המונצח:</label>
					<input	type="text"	name="name"	v-model="item.name"	/>
				</div>
				<label class="small_title">עבור מה תקדישו את ההנצחה?</label>
				<div class="input_wrapper">
					<wzselectVue :options="$store.categories.map(row => { return { key: row.id, val: row.name } })" 
					:settings="{placeholder:'זיווג,פרנסה וכו',width: 'auto'}" v-model="item.category" />
				</div>
				<div class="input_wrapper" v-if="item.category == 7"> 
					<label>הקדשה: </label>
					<input	type="text"	name="name" maxlength="15"	v-model="item.free_text"/>
				</div>
				<label class="small_title">בחרו תאריך להנצחת היום</label>
				<div class="input_wrapper date">
					<div class="date_wrapper">
						<wzselectVue :options="days.map(row => { return { key: row, val: row } })"  :settings="{ label: 'יום', width: 'auto'}" v-model="item.day" />
						<wzselectVue :options="months.map(row => { return { key: row, val: row } })"  :settings="{ label: ' חודש', width: 'auto'}" v-model="item.month" />
					</div>
				</div>
			</form>
		</div>
         <button  v-if="names && names.length > 0 " class="btn" @click="saveForm()">שמירה</button>
        <h2 v-if="items && items.length > 0 "> שלום {{ name }}, השמות שלי</h2>
        <natziv :items="items"/>
	</div>
</template>

<script>
import natziv from "@/components/natziv.vue";
import wzselectVue from "@/components/inputs/wzselect.vue";

export default {
	components: {  natziv,wzselectVue},
	data() {
		return {
			items: [],
			names: [],
			name: '',
			names_remain: 0,
			step: 1,
            days:["א","ב","ג","ד","ה","ו","ז","ח","ט","י",'י"א','י"ב','י"ג','י"ד','ט"ו','ט"ז','י"ז','י"ח','י"ט','כ','כ"א','כ"ב','כ"ג','כ"ד','כ"ה','כ"ו','כ"ז','כ"ח','כ"ט','ל'],
			months:["תשרי","חשוון","כסלו","טבת","שבט","אדר א","אדר ב","ניסן","אייר","סיוון","תמוז","אב","אלול"],
            token:this.$route.params.token,
			loaded: false,
		};
	},
	computed: {},
	beforeUnmount() {},
	mounted() {
		this.api({ action: "get_sub", method: "get",data:{token:this.token} }, (data) => {
			this.items = data.data.items;
			this.names_remain = data.data.names_remain;
			this.name = data.data.order.name;

            if(this.names_remain && this.names_remain > 0)
            {
                for(let i = 0;i < this.names_remain; i++)
                {
                    this.names.push({name_pay: data.data.order.name,anonymous:false,name:'',category:1,day:'א',month:'תשרי',free_text:''});
                }
            }

            this.$nextTick(()=>{
                this.loaded = true;
            });
		});
	},
	methods: {
        saveForm() {
			this.api({ action: "save_names", data:{names:this.names,token:this.token} }, (data) => {
				if(data.data)
                {
                    alert("השמות נשמרו בהצלחה!");
                    this.$bus.$emit("reload");
                }
			});
		},
    },
};
</script>
<style lang="scss" scoped>
h2{text-align: center;padding: 20px 0;color: #fff;font-size: 40px;}
.btn{display: block;margin:  30px auto !important; font-size: 30px !important;width: fit-content;padding: 10px 50px;height: auto;}
.forms_wrapper {
		&{display: flex;gap:20px;justify-content: center; align-items: center;}
		@media (min-width: 600px) {  }
		@media (max-width: 600px) { flex-direction: column; width: 100%;}
	}
    form {
		&{display: flex;flex-direction: column;justify-content: space-between;gap:15px;align-items: center;background: white;width: fit-content;
        border-radius: 30px;}
		@media (min-width: 600px) { padding: 50px; }
		@media (max-width: 600px) { padding: 20px;width: 100%; }
		.small_title{font-size: 20px;margin-bottom: -10px;}
		
        .title {font-size: 40px;text-align: center;}
        .share {display: flex;gap:10px;justify-content: center; align-items: center;}
    }
    .btn {font-size: 24px;margin: 20px 0;}
	.checkbox {align-self: flex-start;padding-right: 10px;font-size: 22px;}
	
</style>